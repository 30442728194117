import request from '@/utils/request'

export function GetApplicantExpDetails() {
  return request({
    url: '/api/ApplicantExpDetails/GetApplicantExpDetails',
    method: 'get',
  })
}

export function GetApplicantExpDetailById(data) {
  return request({
    url: `/api/ApplicantExpDetails/GetApplicantExpDetailById/${data}`,
    method: 'get',
  })
}

export function GetApplicantExpDetailByApplicantId(data) {
  return request({
    url: `/api/ApplicantExpDetails/GetApplicantExpDetailByApplicantId/${data}`,
    method: 'get',
  })
}

export function AddApplicantExpDetail(data) {
  return request({
    url: '/api/ApplicantExpDetails/AddApplicantExpDetail',
    method: 'post',
    data,
  })
}

export function UpdateApplicantExpDetail(data) {
  return request({
    url: '/api/ApplicantExpDetails/UpdateApplicantExpDetail',
    method: 'put',
    data,
  })
}

export function DeleteApplicantExpDetail(data) {
  return request({
    url: `/api/ApplicantExpDetails/DeleteApplicantExpDetail?applicantExpDetailId=${data}&hardDelete=true`,
    method: 'delete',
  })
}
