<template>
  <div>
    <b-row>
      <b-col>
        <div id="Not-grid-container">
          <b-form-group>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-modal.notadd-lg
              class="d-flex align-items-left"
              squared
              variant="relief-primary"
              size="sm"
              :disabled="getAdayId < 0"
            >
              <feather-icon
                icon="AddIcon"
                class="mr-50"
              />
              <span>Aday için not ekle</span>
            </b-button>
          </b-form-group>
        </div>
        <!-- table -->
        <vue-good-table
          :columns="notCols"
          :rows="notRows"
          :search-options="{
            enabled: false }"
          :pagination-options="{
            enabled: false }"
          theme="my-theme"
          @on-row-click="onDataGridItemClicked"
        >
          <div slot="emptystate">
            <center> Aday için not bilgisi kaydı bulunmuyor.</center>
          </div>
          <template
            slot="table-row"
            slot-scope="props"
          >
            <!-- Column: Action -->
            <span v-if="props.column.field === 'action'">
              <span>
                <template>
                  <b-form-group
                    class="mt-25"
                    size="sm"
                  >
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      size="sm"
                      squared
                      class="mr-1"
                      variant="outline-warning"
                      @click="onUpdateNotBtnClicked(props.row)"
                    >
                      <feather-icon
                        icon="Edit2Icon"
                        class="mr-50"
                      />
                      <span>Düzenle</span>
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      size="sm"
                      squared
                      variant="outline-danger"
                      @click="onDeleteNotBtnClicked(props.row)"
                    >
                      <feather-icon
                        icon="TrashIcon"
                        class="mr-50"
                      />
                      <span>Sil</span>
                    </b-button>
                  </b-form-group>
                </template>
              </span>
            </span>
            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
        <b-modal
          id="notadd-lg"
          cancel-variant="outline-secondary"
          ok-title="Ekle"
          size="lg"
          cancel-title="İptal"
          centered
          no-close-on-backdrop
          style="height:700px;"
          title="Not Ekle"
          @ok="onModalSaveNot"
          @cancel="onModalCancelled"
        >
          <b-form>
            <b-row>
              <b-col>
                <b-form-group>
                  <label
                    for="applicantNotId"
                    hidden
                  >
                    Sıra No:</label>
                  <b-form-input
                    id="applicantNotId"
                    v-model="applicantNotId"
                    type="text"
                    placeholder="Sıra No"
                    hidden
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
              >
                <b-form-group>
                  <label for="notes">Not:</label>
                  <b-form-input
                    id="notes"
                    v-model="notes"
                    class="small"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="6"
                md="6"
              >
                <!-- human friendly -->
                <b-form-group
                  label-for="noteDate"
                  label="Tarih"
                >
                  <!-- <flat-pickr
                    id="noteDate"
                    v-model="noteDate"
                    class="form-control"
                    placeholder="Tarih Seçiniz"
                    :config="{ altInput: true,altFormat: 'd.m.Y', dateFormat: 'Y-m-d', locale: 'tr-TR', }"
                  />
                  <br> -->
                  <b-calendar
                    id="takvim"
                    v-model="noteDate"
                    v-bind="labels[locale] || {}"
                    :locale="locale"
                    :hide-header="hideHeader"
                    :show-decade-nav="showDecadeNav"
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </b-modal>
        <!-- Notlar Güncelle  MODAL -->
        <b-modal
          id="NotUpd-modal"
          cancel-variant="outline-secondary"
          ok-title="Güncelle"
          cancel-title="İptal"
          size="lg"
          centered
          no-close-on-backdrop
          style="height:700px;"
          title="Not Güncelle"
          @ok="onModalUpdateNot"
          @cancel="onModalCancelled"
        >
          <b-form>
            <b-row>
              <b-col>
                <b-form-group>
                  <label
                    for="applicantNotId"
                    hidden
                  >
                    Sıra No:</label>
                  <b-form-input
                    id="applicantNotId"
                    v-model="applicantNotId"
                    type="text"
                    placeholder="Sıra No"
                    hidden
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
              >
                <b-form-group>
                  <label for="notes">Not:</label>
                  <b-form-input
                    id="notes"
                    v-model="notes"
                    class="small"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                md="4"
              >
                <!-- human friendly -->
                <b-form-group
                  label-for="noteDate"
                  label="Tarih"
                >
                  <!-- <flat-pickr
                    id="noteDate"
                    v-model="noteDate"
                    class="form-control"
                    :config="{ altInput: true,altFormat: 'd.m.Y', dateFormat: 'Y-m-d', }"
                  /> -->
                  <b-calendar
                    id="takvim"
                    v-model="noteDate"
                    v-bind="labels[locale] || {}"
                    :locale="locale"
                    :hide-header="hideHeader"
                    :show-decade-nav="showDecadeNav"
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </b-modal>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BRow, BCol, BFormGroup, BButton, BFormInput, BModal, BForm, VBModal, BCalendar,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
// import flatPickr from 'vue-flatpickr-component'
import { VueGoodTable } from 'vue-good-table'
import * as apiApNot from '@/api/islemler/applicant/applicantNotes'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  components: {
    VueGoodTable, BRow, BCol, BFormGroup, BButton, BFormInput, BModal, BForm, BCalendar, // flatPickr,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  props: {
    applicantId: {
      type: Number,
      required: false,
      default: -1,
    },
  },
  data() {
    return {
      showDecadeNav: false,
      hideHeader: false,
      locale: 'tr-TR',
      locales: [
        { value: 'tr-TR', text: 'Türkçe' },
      ],
      labels: {
        'tr-TR': {
          labelPrevDecade: 'Vorheriges Jahrzehnt',
          labelPrevYear: 'Vorheriges Jahr',
          labelPrevMonth: 'Vorheriger Monat',
          labelCurrentMonth: 'Aktueller Monat',
          labelNextMonth: 'Nächster Monat',
          labelNextYear: 'Nächstes Jahr',
          labelNextDecade: 'Nächstes Jahrzehnt',
          labelToday: 'Heute',
          labelSelected: 'Ausgewähltes Datum',
          labelNoDateSelected: 'Tarih Seçilmedi',
          labelCalendar: 'Kalender',
          labelNav: 'Kalendernavigation',
          labelHelp: '',
        },
      },
      optionNot: [],
      optionANot: [],
      notCols: [
        {
          label: 'Sıra No',
          field: 'applicantNotId',
          hidden: true,
        },
        {
          label: 'Not',
          field: 'notes',
        },
        {
          label: 'Tarih',
          field: 'noteDate',
          dateOutputFormat: 'dd.mm.yyyy',
        },
        {
          label: 'İşlem',
          field: 'action',
          sortable: false,
          width: '15%',
        },
      ],
      notRows: [],
      applicantNotId: 0,
      appId: this.applicantId,
      notes: '',
      noteDate: '',
    }
  },
  computed: {
    ...mapGetters({
      applicant: 'applicantInfo/GET_APPLICANT',
      getAdayId: 'applicantInfo/GET_ADAYID',
    }),
  },
  watch: {
    applicantId() {
      this.getApplicantData()
    },
  },
  created() {
    this.getNotes()
  },
  methods: {
    ...mapMutations({
      setApplicant: 'applicantInfo/SET_APPLICANT',
    }),
    ...mapActions({
      fetchApplicant: 'applicantInfo/action_get_applicant',
    }),
    getApplicantData() {
      if (this.getAdayId > 0) {
        this.fetchApplicant(this.getAdayId)
        this.appId = this.getAdayId
        this.applicantId = this.getAdayId
        this.getNotes()
      }
    },
    getNotes() {
      apiApNot.GetApplicantNotesByApplicantId(this.appId).then(res => {
        this.notRows = res.data.list
      })
    },
    clearModal() {
      this.applicantNotId = 0
      this.notes = ''
      this.noteDate = ''
    },
    onDataGridItemClicked(params) {
      return params
    },
    onModalSaveNot() {
      const notModel = {
        applicantId: this.getAdayId,
        notes: this.notes,
        noteDate: this.noteDate,
      }
      apiApNot.AddApplicantNotes(notModel).then(() => {
        this.getNotes()
        this.clearModal()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Not ekle',
            text: 'Yeni kayıt eklendi.',
            icon: 'PlusSquareIcon',
            variant: 'success',
          },
        })
        this.clearModal()
        this.appId = this.getAdayId
        this.getNotes()
      })
    },
    onModalCancelled() {
      this.clearModal()
    },
    onModalUpdateNot() {
      const notModel = {
        applicantNotId: this.applicantNotId,
        applicantId: this.appId,
        notes: this.notes,
        noteDate: this.noteDate,
      }
      apiApNot.UpdateApplicantNotes(notModel).then(() => {
        this.getNotes()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Not güncelle',
            text: 'Kayıt güncellendi.',
            icon: 'EditIcon',
            variant: 'warning',
          },
        })
      })
    },
    onUpdateNotBtnClicked(row) {
      this.applicantNotId = row.applicantNotId
      this.notes = row.notes
      this.noteDate = row.noteDate
      this.$bvModal.show('NotUpd-modal')
    },
    onDeleteNotBtnClicked(row) {
      this.$bvModal.msgBoxConfirm(` '${row.noteDate}' tarihli not silinecek, onaylıyor musunuz?`, {
        title: 'Uyarı!',
        size: 'md',
        buttonSize: 'md',
        okVariant: 'danger',
        okTitle: 'Evet',
        cancelTitle: 'Hayır',
        footerClass: 'p-2',
        hideHeaderClose: true,
        noCloseOnBackdrop: true,
        centered: true,
      })
        .then(value => {
          if (value) {
            apiApNot.DeleteApplicantNotes(row.applicantNotId).then(() => {
              this.getNotes()
            })
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Kayıt Silindi',
                text: `${row.noteDate} tarihli kayıt silindi.`,
                icon: 'DeleteIcon',
                variant: 'danger',
              },
            })
          }
        })
      this.clearModal()
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
