<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="4"
      >
        <!-- human friendly -->
        <b-form-group
          label-for="takvim"
          label="Doğum Tarihi"
        >
          <flat-pickr
            id="takvim"
            v-model="applicant.birthDate"
            class="form-control"
            :config="{ altInput: true,altFormat: 'd.m.Y', dateFormat: 'Y-m-d',}"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group
          label="İli"
          label-for="appCity"
        >
          <v-select
            id="appCity"
            v-model="city"
            label="cityName"
            :options="optionCity"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group
          label="İlçe"
          label-for="appTown"
        >
          <v-select
            id="appTown"
            v-model="town"
            label="townName"
            :options="optionTown"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="6"
        md="2"
      >
        <b-form-group
          label="Cinsiyet"
          label-for="gender"
          class="border"
        >
          <b-row class="ml-1">
            <b-col
              cols="6"
              md="6"
            >
              <b-form-radio
                v-model="gender"
                plain
                name="gender"
                value="1"
              >
                Erkek
              </b-form-radio>
            </b-col>
            <b-col
              cols="6"
              md="6"
            >
              <b-form-radio
                v-model="gender"
                plain
                name="gender"
                value="2"
              >
                Kadın
              </b-form-radio>
            </b-col>
            <b-col cols="6">
                &nbsp;
            </b-col>
          </b-row>
        </b-form-group>
      </b-col>
      <b-col
        cols="6"
        md="2"
      >
        <b-form-group
          label="Medeni Hali"
          label-for="maritalStatus"
          class="border"
        >
          <b-row class="ml-1">
            <b-col
              cols="6"
              md="6"
            >
              <b-form-radio
                v-model="applicant.maritalStatus"
                plain
                name="maritalStatus"
                value="1"
              >
                Evli
              </b-form-radio>
            </b-col>
            <b-col
              cols="6"
              md="6"
            >
              <b-form-radio
                v-model="applicant.maritalStatus"
                plain
                name="maritalStatus"
                value="2"
              >
                Bekar
              </b-form-radio>
            </b-col>
            <b-col cols="6">
                &nbsp;
            </b-col>
          </b-row>
        </b-form-group>
      </b-col>
      <b-col
        class="border"
        cols="12"
        md="8"
      >
        <b-row>
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="Askerlik Durumu"
              label-for="militaryStatus"
            >
              <b-row>
                <b-col
                  cols="3"
                  md="3"
                >
                  <b-form-radio
                    v-model="militaryStatus"
                    plain
                    name="militaryStatus"
                    value="1"
                  >
                    Yapıldı
                  </b-form-radio>
                </b-col>
                <b-col
                  cols="3"
                  md="3"
                >
                  <b-form-radio
                    v-model="militaryStatus"
                    plain
                    name="militaryStatus"
                    value="2"
                  >
                    Yapılmadı
                  </b-form-radio>
                </b-col>
                <b-col
                  cols="3"
                  md="3"
                >
                  <b-form-radio
                    v-model="militaryStatus"
                    plain
                    name="militaryStatus"
                    value="3"
                  >
                    Muaf
                  </b-form-radio>
                </b-col>
                <b-col
                  cols="3"
                  md="3"
                >
                  <b-form-radio
                    v-model="militaryStatus"
                    plain
                    name="militaryStatus"
                    value="4"
                  >
                    Tecilli
                  </b-form-radio>
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>
          <b-col
            cols="6"
            md="6"
          >
            <!-- human friendly -->
            <b-form-group
              label-for="takvim"
              label="Tecil Tarihi"
            >
              <flat-pickr
                id="takvim"
                v-model="applicant.endDateDelay"
                class="form-control"
                :config="{ altInput: true,altFormat: 'd.m.Y', dateFormat: 'Y-m-d',}"
                :disabled="militaryStatus!=4"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row class="mt-1">
      <b-col
        cols="12"
        md="3"
      >
        <b-form-group
          label="Boyunuz (Cm)"
          label-for="heightCm"
        >
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="SlidersIcon" />
            </b-input-group-prepend>
            <b-form-input
              id="heightCm"
              v-model="applicant.heightCm"
              placeholder="Boyunuzu giriniz"
              size="sm"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="3"
      >
        <b-form-group
          label="Kilonuz (Kg)"
          label-for="weightKg"
        >
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="LayersIcon" />
            </b-input-group-prepend>
            <b-form-input
              id="weightKg"
              v-model="applicant.weightKg"
              placeholder="Kilonuzu giriniz"
              size="sm"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="3"
      >
        <b-form-group
          label="Engellilik Durumu"
          label-for="isDisabled"
          class="border"
        >
          <b-row class="ml-1">
            <b-col
              cols="6"
              md="6"
            >
              <b-form-radio
                v-model="applicant.isDisabled"
                plain
                name="isDisabled"
                :value="true"
              >
                Var
              </b-form-radio>
            </b-col>
            <b-col
              cols="6"
              md="6"
            >
              <b-form-radio
                v-model="applicant.isDisabled"
                plain
                name="isDisabled"
                :value="false"
              >
                Yok
              </b-form-radio>
            </b-col>
            <b-col cols="6">
              &nbsp;
            </b-col>
          </b-row>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="3"
      >
        <b-form-group
          label="Sigara Kullanımı"
          label-for="isSmoker"
          class="border"
        >
          <b-row class="ml-1">
            <b-col
              cols="6"
              md="6"
            >
              <b-form-radio
                v-model="applicant.isSmoker"
                plain
                name="isSmoker"
                :value="true"
              >
                Var
              </b-form-radio>
            </b-col>
            <b-col
              cols="6"
              md="6"
            >
              <b-form-radio
                v-model="applicant.isSmoker"
                plain
                name="isSmoker"
                :value="false"
              >
                Yok
              </b-form-radio>
            </b-col>
            <b-col cols="6">
              &nbsp;
            </b-col>
          </b-row>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
        md="3"
      >
        <b-form-group
          label="Seyahat Durumu"
          label-for="canTravel"
          class="border"
        >
          <b-row class="ml-1">
            <b-col
              cols="6"
              md="6"
            >
              <b-form-radio
                v-model="applicant.canTravel"
                plain
                name="canTravel"
                :value="true"
              >
                Var
              </b-form-radio>
            </b-col>
            <b-col
              cols="6"
              md="6"
            >
              <b-form-radio
                v-model="applicant.canTravel"
                plain
                name="canTravel"
                :value="false"
              >
                Yok
              </b-form-radio>
            </b-col>
            <b-col cols="6">
      &nbsp;
            </b-col>
          </b-row>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="3"
      >
        <b-form-group
          label="Vardiyalı Çalışabilme"
          label-for="hasWorkShifts"
          class="border"
        >
          <b-row class="ml-1">
            <b-col
              cols="6"
              md="6"
            >
              <b-form-radio
                v-model="applicant.hasWorkShifts"
                plain
                name="workShifts"
                :value="true"
              >
                Var
              </b-form-radio>
            </b-col>
            <b-col
              cols="6"
              md="6"
            >
              <b-form-radio
                v-model="applicant.hasWorkShifts"
                plain
                name="workShifts"
                :value="false"
              >
                Yok
              </b-form-radio>
            </b-col>
            <b-col cols="6">
      &nbsp;
            </b-col>
          </b-row>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="3"
      >
        <b-form-group
          label="Referans Verebilme"
          label-for="hasReferences"
          class="border"
        >
          <b-row class="ml-1">
            <b-col
              cols="6"
              md="6"
            >
              <b-form-radio
                v-model="applicant.hasReferences"
                plain
                name="hasReferences"
                :value="true"
              >
                Var
              </b-form-radio>
            </b-col>
            <b-col
              cols="6"
              md="6"
            >
              <b-form-radio
                v-model="applicant.hasReferences"
                plain
                name="hasReferences"
                :value="false"
              >
                Yok
              </b-form-radio>
            </b-col>
            <b-col cols="6">
              &nbsp;
            </b-col>
          </b-row>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="3"
      >
        <b-form-group
          label="Sabıka Kaydı"
          label-for="hasCriminalRecord"
          class="border"
        >
          <b-row class="ml-1">
            <b-col
              cols="6"
              md="6"
            >
              <b-form-radio
                v-model="applicant.hasCriminalRecord"
                plain
                name="hasCriminalRecord"
                :value="true"
              >
                Var
              </b-form-radio>
            </b-col>
            <b-col
              cols="6"
              md="6"
            >
              <b-form-radio
                v-model="applicant.hasCriminalRecord"
                plain
                name="hasCriminalRecord"
                :value="false"
              >
                Yok
              </b-form-radio>
            </b-col>
            <b-col cols="6">
              &nbsp;
            </b-col>
          </b-row>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
        md="3"
      >
        <b-form-group
          label="Aktif Araç Kullanımı"
          label-for="canDrive"
          class="border"
        >
          <b-row class="ml-1">
            <b-col
              cols="6"
              md="6"
            >
              <b-form-radio
                v-model="applicant.canDrive"
                plain
                name="canDrive"
                :value="true"
              >
                Var
              </b-form-radio>
            </b-col>
            <b-col
              cols="6"
              md="6"
            >
              <b-form-radio
                v-model="applicant.canDrive"
                plain
                name="canDrive"
                :value="false"
              >
                Yok
              </b-form-radio>
            </b-col>
            <b-col cols="6">
      &nbsp;
            </b-col>
          </b-row>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="3"
      >
        <b-form-group
          label="Ehliyet Sınıfı"
          label-for="DriverLicenseType"
          class="border"
        >
          <v-select
            id="firmType"
            v-model="applicant.driverLicenseType"
            label="driverLicenceTypeName"
            :options="optionEhl"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group
          label="Kronik Rahatsızlıklar"
          label-for="obstacleDiseases"
          class="border"
        >
          <v-select
            id="obstacleDiseases"
            v-model="applicant.obstacleDiseases"
            label="obstacleDiseaseName"
            :options="optionKronik"
            :reduce="item=> item.obstacleDiseaseName"
            multiple
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BInputGroup, BInputGroupPrepend,
  BFormRadio, // BButton, BFormFile,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import * as apiCity from '@/api/tanimlar/sehir'
import * as apiTown from '@/api/tanimlar/town'
import * as apiEhl from '@/api/tanimlar/DriverLicenceType'
import * as apiKronik from '@/api/tanimlar/ObstacleDisease'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  components: {
    // eslint-disable-next-line object-property-newline
    vSelect, BRow, BCol, BFormGroup, BFormInput, BInputGroup, BInputGroupPrepend, flatPickr,
    BFormRadio,
    // BButton, BFormFile,
  },
  directives: {
    Ripple,
  },
  props: {
    applicantId: {
      type: Number,
      required: false,
      default: -1,
    },
  },
  data() {
    return {
      optionCity: [],
      optionTown: [],
      optionEhl: [],
      optionKronik: [],
      city: [],
      town: [],
      formatted: '',

      btarih: '',
      cityId: 0,
      townId: 0,
      gender: null,
      militaryStatus: null,
    }
  },
  computed: {
    ...mapGetters({
      applicant: 'applicantInfo/GET_APPLICANT',
    }),
  },

  watch: {
    applicantId() {
      this.getApplicantData()
    },
    gender() {
      this.applicant.gender = this.gender
      if (this.gender === '2') this.militaryStatus = 3
    },
    militaryStatus() {
      if (this.gender === '2') this.militaryStatus = 3
      this.applicant.militaryStatus = this.militaryStatus
    },
    city() {
      this.cityId = this.city.cityId
      this.applicant.birthCity = this.city.cityName
      this.town.townName = 'İlçe Seçiniz'
      this.getTowns()
    },
    town() {
      this.townId = this.town.townId
      this.applicant.birthTown = this.town.townName
    },
  },
  created() {
    this.getCities()
    this.getTowns()
    this.getEhliyet()
    this.getKronik()
  },
  mounted() {
    this.getApplicantData()
  },
  methods: {
    ...mapMutations({
      setApplicant: 'applicantInfo/SET_APPLICANT',
    }),
    ...mapActions({
      fetchApplicant: 'applicantInfo/action_get_applicant',
    }),
    getApplicantData() {
      if (this.applicantId > 0) {
        this.fetchApplicant(this.applicantId).then(() => {
          this.gender = this.applicant.gender
          this.militaryStatus = this.applicant.militaryStatus
          if (this.applicant.birthCity !== '') {
            apiCity.getCityByCityName(this.applicant.birthCity).then(res => {
              this.city = res.data.entity
              this.cityId = res.data.entity.cityId
              this.getTowns()
              apiTown.GetTownByTownName(this.applicant.birthTown).then(r => {
                this.town = r.data.entity
                this.townId = r.data.entity.townId
              })
            })
          }
        })
      }
    },

    getCities() {
      apiCity.getCityByCountryId(1).then(res => {
        this.optionCity = res.data.list
      })
    },
    getTowns() {
      apiTown.GetTownsByCityId(this.cityId).then(res => {
        this.optionTown = res.data.list
      })
    },
    getEhliyet() {
      apiEhl.getDriverLicenceTypes().then(res => {
        this.optionEhl = res.data.list
      })
    },
    getKronik() {
      apiKronik.getObstacleDiseases().then(res => {
        this.optionKronik = res.data.list
      })
    },
  },
}
</script>
<style lang='scss'>
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
