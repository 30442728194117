<template>
  <div>
    <b-row>
      <b-col>
        <div id="Lang-grid-container">
          <b-form-group>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-modal.langadd-lg
              class="d-flex align-items-left"
              squared
              variant="relief-warning"
              size="sm"
              :disabled="getAdayId <= 0"
            >
              <feather-icon
                icon="AddIcon"
                class="mr-50"
              />
              <span>Yabancı Dil Ekle</span>
            </b-button>
          </b-form-group>
        </div>
        <!-- table -->
        <vue-good-table
          :columns="langCols"
          :rows="langRows"
          :search-options="{
            enabled: false }"
          :pagination-options="{
            enabled: false }"
          theme="my-theme"
          @on-row-click="onDataGridItemClicked"
        >
          <div slot="emptystate">
            <center> Aday yabancı dil kaydı bulunmuyor.</center>
          </div>
          <template
            slot="table-row"
            slot-scope="props"
          >
            <!-- Column: Action -->
            <span v-if="props.column.field === 'action'">
              <span>
                <template>
                  <b-button-group
                    class="mt-25"
                    size="sm"
                  >
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      size="sm"
                      squared
                      class="mr-1"
                      variant="outline-warning"
                      @click="onUpdateLangBtnClicked(props.row)"
                    >
                      <feather-icon
                        icon="Edit2Icon"
                        class="mr-50"
                      />
                      <span>Düzenle</span>
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      size="sm"
                      squared
                      variant="outline-danger"
                      @click="onDeleteLangBtnClicked(props.row)"
                    >
                      <feather-icon
                        icon="TrashIcon"
                        class="mr-50"
                      />
                      <span>Sil</span>
                    </b-button>
                  </b-button-group>
                </template>
              </span>
            </span>
            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
        <b-modal
          id="langadd-lg"
          cancel-variant="outline-secondary"
          ok-title="Ekle"
          size="lg"
          cancel-title="İptal"
          centered
          style="height:700px;"
          title="Yabancı Dil Ekle"
          no-close-on-backdrop
          @show="onLoadSelects"
          @ok="onModalSaveLang"
          @cancel="onModalCancelled"
        >
          <b-form>
            <b-row>
              <b-col>
                <b-form-group>
                  <label
                    for="queNumber"
                    hidden
                  >
                    Sıra No:</label>
                  <b-form-input
                    id="queNumber"
                    v-model="queNumber"
                    type="text"
                    placeholder="Sıra No"
                    hidden
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group>
                  <label for="subject">Yabancı Dil:</label>
                  <v-select
                    id="subject"
                    v-model="subject"
                    class="small"
                    label="languageName"
                    :options="optionLang"
                    :reduce="item => item.languageName"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group>
                  <label for="subjectLevel">Sınavı:</label>
                  <v-select
                    id="subjectLevel"
                    v-model="subjectLevel"
                    label="foreignLangLevelDescription"
                    :options="optionLevel"
                    :reduce="item => item.foreignLangLevelDescription"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group>
                  <label for="languagePoint">Puanı:</label>
                  <b-form-input
                    id="languagePoint"
                    v-model="languagePoint"
                    type="number"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </b-modal>
        <!-- Yabancı Dil Güncelle  MODAL -->
        <b-modal
          id="LangGuncelle-modal"
          cancel-variant="outline-secondary"
          ok-title="Güncelle"
          cancel-title="İptal"
          size="lg"
          centered
          no-close-on-backdrop
          title="Yabancı Dil Güncelle"
          @ok="onModalUpdateLang"
          @cancel="onModalCancelled"
        >
          <b-form>
            <b-row>
              <b-col>
                <b-form-group>
                  <label for="subject">Yabancı Dil:</label>
                  <v-select
                    id="subject"
                    v-model="usubject"
                    class="small"
                    label="languageName"
                    :options="optionLang"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group>
                  <label for="subjectLevel">Seviyesi:</label>
                  <v-select
                    id="subjectLevel"
                    v-model="usubjectLevel"
                    label="foreignLangLevelDescription"
                    :options="optionLevel"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group>
                  <label for="languagePoint">Puanı:</label>
                  <b-form-input
                    id="languagePoint"
                    v-model="languagePoint"
                    type="number"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </b-modal>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BRow, BCol, BFormGroup, BButton, BFormInput, BModal, BForm, BButtonGroup, VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { VueGoodTable } from 'vue-good-table'
import * as apiLevel from '@/api/tanimlar/LanguageLevels'
import * as apiLang from '@/api/tanimlar/Language'
import * as apiAppLangs from '@/api/islemler/applicant/applicantLangs'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  components: {
    VueGoodTable, BRow, BCol, BFormGroup, BButton, BFormInput, BModal, BForm, BButtonGroup, vSelect,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  props: {
    applicantId: {
      type: Number,
      required: false,
      default: -1,
    },
  },
  data() {
    return {
      optionLang: [],
      optionLevel: [],
      applicantFLId: 0,
      languagePoint: 0,
      langCols: [
        {
          label: 'Sıra No',
          field: 'queNumber',
          hidden: true,
        },
        {
          label: 'Yabancı Dil',
          field: 'language',
        },
        {
          label: 'Sınav Türü',
          field: 'languageLevel',
        },
        {
          label: 'Puanı',
          field: 'languagePoint',
        },
        {
          label: 'İşlem',
          field: 'action',
          sortable: false,
          width: '15%',
        },
      ],
      langRows: [],
      queNumber: 1,
      subject: { languageId: 0, languageName: '', languageDescription: '' },
      subjectLevel: { foreignLangLevelId: 0, foreignLangLevelDescription: '' },

      usubject: { languageId: 0, languageName: '', languageDescription: '' },
      usubjectLevel: { foreignLangLevelId: 0, foreignLangLevelDescription: '' },
      appId: this.applicantId,
    }
  },
  computed: {
    ...mapGetters({
      applicant: 'applicantInfo/GET_APPLICANT',
      getAdayId: 'applicantInfo/GET_ADAYID',
    }),
  },
  watch: {
    applicantId() {
      this.getApplicantData()
    },
  },
  created() {
    this.onLoadSelects()
    this.getApplicantData()
    this.getAppLang()
  },
  methods: {
    ...mapMutations({
      setApplicant: 'applicantInfo/SET_APPLICANT',
    }),
    ...mapActions({
      fetchApplicant: 'applicantInfo/action_get_applicant',
    }),
    getApplicantData() {
      if (this.getAdayId > 0) {
        this.fetchApplicant(this.getAdayId)
        this.getAppLang()
      }
    },
    getAppLang() {
      apiAppLangs.GetApplicantForeignLangByApplicantId(this.appId).then(res => {
        this.langRows = res.data.list
        if (res.data.list == null) this.queNumber = 1
        else this.queNumber = res.data.count + 1
      })
    },
    getLevels() {
      apiLevel.GetForeignLangLevels().then(res => {
        this.optionLevel = res.data.list
      })
    },
    getLang() {
      apiLang.getLanguages().then(res => {
        this.optionLang = res.data.list
      })
    },
    clearModal() {
      this.subject = { languageId: 0, languageName: '', languageDescription: '' }
      this.subjectLevel = { foreignLangLevelId: 0, foreignLangLevelDescription: '' }
      this.usubject = { languageId: 0, languageName: '', languageDescription: '' }
      this.usubjectLevel = { foreignLangLevelId: 0, foreignLangLevelDescription: '' }
      this.languagePoint = ''
      this.getAppLang()
    },
    onDataGridItemClicked(params) {
      return params
    },
    onModalSaveLang() {
      const langModel = {
        applicantFLId: 0,
        applicantId: this.getAdayId,
        queNumber: this.queNumber,
        language: this.subject,
        languageLevel: this.subjectLevel,
        languagePoint: this.languagePoint,
      }
      apiAppLangs.AddApplicantForeignLang(langModel).then(() => {
        this.appId = this.getAdayId
        this.getAppLang()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Kayıt Eklendi',
            text: 'Kayıt başarıyla eklendi',
            icon: 'EditIcon',
            variant: 'warning',
          },
        })
        this.appId = this.getAdayId
        this.getAppLang()
      })
      this.clearModal()
    },
    onModalCancelled() {
      this.clearModal()
    },
    onUpdateLangBtnClicked(row) {
      this.onLoadSelects()
      this.applicantFLId = row.applicantFLId
      this.queNumber = row.queNumber
      this.usubject.languageName = row.language
      this.usubjectLevel.foreignLangLevelDescription = row.languageLevel
      this.languagePoint = row.languagePoint
      this.$bvModal.show('LangGuncelle-modal')
    },
    onModalUpdateLang() {
      const langModel = {
        applicantFLId: this.applicantFLId,
        applicantId: this.getAdayId,
        queNumber: this.queNumber,
        language: this.usubject.languageName,
        languageLevel: this.usubjectLevel.foreignLangLevelDescription,
        languagePoint: this.languagePoint,
      }
      apiAppLangs.UpdateApplicantForeignLang(langModel).then(() => {
        this.getAppLang()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Yabancı Dil bilgisi güncelle.',
            text: 'Yabancı Dil bilgisi güncellendi.',
            icon: 'EditIcon',
            variant: 'warning',
          },
        })
      })
      this.clearModal()
    },
    onDeleteLangBtnClicked(row) {
      this.$bvModal.msgBoxConfirm(`'${row.language}' yabancı dili silinecek, onaylıyor musunuz? `, {
        title: 'Uyarı!',
        size: 'md',
        buttonSize: 'md',
        okVariant: 'danger',
        okTitle: 'Evet',
        cancelTitle: 'Hayır',
        footerClass: 'p-2',
        hideHeaderClose: true,
        noCloseOnBackdrop: true,
        centered: true,
      })
        .then(value => {
          if (value) {
            apiAppLangs.DeleteApplicantForeignLang(row.applicantFLId).then(() => {
              this.getAppLang()
            })
            this.$toast({
              compotent: ToastificationContent,
              props: {
                title: 'Kayıt Silindi',
                text: `${this.language} yabancı dili silindi`,
                icon: 'DeleteIcon',
                variant: 'danger',
              },
            })
          }
        })
      this.clearModal()
    },
    onLoadSelects() {
      this.getLevels()
      this.getLang()
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
