<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <b-row>
          <b-col
            cols="12"
            md="4"
          >
            <label><b> Talep edilen aylık ücret :</b> </label>
          </b-col>
          <b-col
            cols="6"
            md="4"
          >
            <b-form-group
              label="Minimum maaş"
              label-for="salaryMin"
            >
              <b-form-input
                id="salaryMin"
                v-model="appFuncs.salaryMin"
                placeholder="Minimum maaş"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="6"
            md="4"
          >
            <b-form-group
              label="Maksimum maaş"
              label-for="salaryMax"
            >
              <b-form-input
                id="salaryMax"
                v-model="appFuncs.salaryMax"
                placeholder="Maksimum maaş"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            md="10"
          >
            <b-form-group
              label="Performansa bağlı ödeme kabulü"
              label-for="performancePayment"
              class="border font-weight-bolder"
            >
              <b-row class="ml-1">
                <b-col
                  cols="6"
                  md="6"
                >
                  <b-form-radio
                    v-model="appFuncs.performancePayment"
                    plain
                    name="performancePayment"
                    :value="true"
                    class="font-weight-normal"
                  >
                    Evet
                  </b-form-radio>
                </b-col>
                <b-col
                  cols="6"
                  md="6"
                >
                  <b-form-radio
                    v-model="appFuncs.performancePayment"
                    plain
                    name="performancePayment"
                    :value="false"
                    class="font-weight-normal"
                  >
                    Hayır
                  </b-form-radio>
                </b-col>
                <b-col cols="6">
                &nbsp;
                </b-col>
              </b-row>
            </b-form-group>
            <b-col>
              &nbsp;
            </b-col>
          </b-col>
        </b-row>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <p class="fs-3 font-weight-bolder">
          Diğer Sosyal İmkanlar
        </p>
        <b-row>
          <b-col>
            <b-form-group>
              <b-form-checkbox-group
                id="checkbox-group-1"
                v-model="selectedDsi"
                :options="optionsdsi"
                name="flavour-1"
                class="demo-inline-spacing"
              />
              <br>
              <b-form-input
                id="dsi9"
                v-model="appFuncs.socialOtherDescription"
                name="DigerInput"
                :disabled="!selectedDsi.includes('dsi8')"
                placeholder="Açıklayınız."
                size="sm"
              />
            </b-form-group>
          </b-col>
        </b-row>

      </b-col>
    </b-row>
  </div>
</template>
<script>
/*
      "salaryMax": 0,
      "salaryMin": 0,
      "performancePayment": true,
      "socialBonusPayment": true,
      "socialChildBenefit": true,
      "socialHeatingAid": true,
      "socialEducationalAid": true,
      "socialIndividualPension": true,
      "socialLifeInsurance": true,
      "socialUnavailable": true,
      "socialOther": true,
      "socialOtherDescription": "string"
*/
import {
  BRow, BCol, BFormInput, BFormGroup, BFormRadio, BFormCheckboxGroup, // BFormRadio, BForm
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  components: {
    BRow, BCol, BFormInput, BFormGroup, BFormRadio, BFormCheckboxGroup,
  },
  directives: {
    Ripple,
  },
  props: {
    applicantId: {
      type: Number,
      required: false,
      default: -1,
    },
  },
  data() {
    return {
      selectedDsi: [],
      optionsdsi: [
        { text: 'İkramiye', value: 'dsi1' },
        { text: 'Çocuk ve Aile yardımı', value: 'dsi2' },
        { text: 'Yakacak yardımı', value: 'dsi3' },
        { text: 'Eğitim yardımı', value: 'dsi4' },
        { text: 'Bireysel emeklilik', value: 'dsi5' },
        { text: 'Hayat sigortası', value: 'dsi6' },
        { text: 'Yok', value: 'dsi7' },
        { text: 'Diğer seçenekleri açıklayınız', value: 'dsi8' },
      ],
    }
  },
  computed: {
    ...mapGetters({
      applicant: 'applicantInfo/GET_APPLICANT',
      appFuncs: 'applicantInfo/GET_APPLICANTFUNCTIONS',
    }),
  },
  watch: {
    selectedDsi() {
      this.appFuncs.socialBonusPayment = this.selectedDsi.includes('dsi1')
      this.appFuncs.socialChildBenefit = this.selectedDsi.includes('dsi2')
      this.appFuncs.socialHeatingAid = this.selectedDsi.includes('dsi3')
      this.appFuncs.socialEducationalAid = this.selectedDsi.includes('dsi4')
      this.appFuncs.socialIndividualPension = this.selectedDsi.includes('dsi5')
      this.appFuncs.socialLifeInsurance = this.selectedDsi.includes('dsi6')
      this.appFuncs.socialUnavailable = this.selectedDsi.includes('dsi7')
      this.appFuncs.socialOther = this.selectedDsi.includes('dsi8')
    },
    applicantId() {
      this.getApplicantData()
    },
  },
  created() {
    // this.getApplicantData()
  },
  mounted() {
    this.getApplicantData()
  },
  methods: {
    ...mapMutations({
      setApplicant: 'applicantInfo/SET_APPLICANT',
      setAppFuncs: 'applicant/SET_APPLICANTFUNCTIONS',
    }),
    ...mapActions({
      fetchApplicant: 'applicantInfo/action_get_applicant',
      fetchAppFuncs: 'applicantInfo/action_get_applicantFunctions',
    }),
    getApplicantData() {
      if (this.applicantId > 0) {
        this.fetchApplicant(this.applicantId)
        this.fetchAppFuncs(this.applicantId).then(() => {
          this.selectedDsi = []
          if (this.appFuncs.socialBonusPayment) this.selectedDsi.push('dsi1')
          if (this.appFuncs.socialChildBenefit) this.selectedDsi.push('dsi2')
          if (this.appFuncs.socialHeatingAid) this.selectedDsi.push('dsi3')
          if (this.appFuncs.socialEducationalAid) this.selectedDsi.push('dsi4')
          if (this.appFuncs.socialIndividualPension) this.selectedDsi.push('dsi5')
          if (this.appFuncs.socialLifeInsurance) this.selectedDsi.push('dsi6')
          if (this.appFuncs.socialUnavailable) this.selectedDsi.push('dsi7')
          if (this.appFuncs.socialOther) this.selectedDsi.push('dsi8')
        })
      }
    },
  },
}
</script>
