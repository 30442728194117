<template>
  <div>
    <span>Uzman Görüşü </span>
    <b-row class="mt-1">
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group>
          <b-form-textarea
            id="expertsOpinion"
            v-model="abilities.expertsOpinion"
            rows="5"
            size="sm"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BRow, BCol, BFormGroup, BFormTextarea,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapGetters, mapMutations } from 'vuex'

/*
  "applicantNotId": 0,
  "applicantId": 0,
  "notes": "string",
  "noteDate": "2021-08-14T07:49:40.048Z",
*/
export default {
  components: {
    BRow, BCol, BFormGroup, BFormTextarea, // BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    applicantId: {
      type: Number,
      required: false,
      default: -1,
    },
  },
  data() {
    return {
      optionUzman: [],
      optionAUzman: [],
      notRows: [],
      applicantNotId: 0,
      notes: '',
      noteDate: '',
    }
  },
  computed: {
    ...mapGetters({
      applicant: 'applicantInfo/GET_APPLICANT',
      abilities: 'applicantInfo/GET_APPLICANTABILITIES',
    }),
  },
  watch: {
    applicantId() {
      this.getApplicantData()
    },
  },
  created() {
    this.getApplicantData()
  },
  methods: {
    ...mapMutations({
      setApplicant: 'applicantInfo/SET_APPLICANT',
      setAbilities: 'applicantInfo/SET_APPLICANTABILITIES',
    }),
    ...mapActions({
      fetchApplicant: 'applicantInfo/action_get_applicant',
      fetchAbilities: 'applicantInfo/action_get_applicantAbilities',
    }),
    getApplicantData() {
      if (this.applicantId > 0) {
        this.fetchApplicant(this.applicantId)
        this.fetchAbilities(this.applicantId)
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
