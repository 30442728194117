<template>
  <div>
    <div class="mb-1 border p-1">
      <b-form-group>
        <label>Daha önce yaptığım işlerde; müşteri,tedarikçi,yasal merciler veya devletin kuruluşlarına karşı şirketi temsil etmeye yönelik faaliyetler yürüttüm.</label>
        <b-form-radio-group
          v-model="applicantFunctions.q1"
          :options="optionsq01"
        />
      </b-form-group>
    </div>
    <div class="mb-1 border p-1">
      <b-form-group>
        <label>Daha önce yaptığım işlerde; belirli hesaplamalara bağlı olarak planlama yaptım. Çalıştığım birimdeki veya diğer birimlerdeki çalışanlar benim yaptığım planlara göre çalışmalarını yürütürdü.</label>
        <b-form-radio-group
          v-model="applicantFunctions.q2"
          :options="optionsq02"
        />
      </b-form-group>
    </div>
    <div class="mb-1 border p-1">
      <b-form-group>
        <label>Daha önce yaptığım işlerde; bir ya da birkaç personelin yaptıkları işlerde karşılaştıkları problemlere çözüm bulur ve aralarındaki koordinasyonu sağlamaya yönelik çalışmalar yapardım.</label>
        <b-form-radio-group
          v-model="applicantFunctions.q3"
          :options="optionsq03"
        />
      </b-form-group>
    </div>
    <div class="mb-1 border p-1">
      <b-form-group>
        <label>Daha önce yaptığım işlerde; bir ya da birkaç personelin yaptıkları işleri kontrol eder, yapılan işlerin iş emirlerine,talimatlara vb. uygun olup olmadığı gibi çalışmalar yapardım. </label>
        <b-form-radio-group
          v-model="applicantFunctions.q4"
          :options="optionsq04"
        />
      </b-form-group>
    </div>
    <div class="mb-1 border p-1">
      <b-form-group>
        <label>Daha önce yaptığım işlerde; belirli metotlar ile model çıkarma,hesaplama,reçete hazırlama,boyutlandırma,maliyetlendirme gibi tasarım,hesaplama ve kurguya yönelik çalışmalar yapardım.</label>
        <b-form-radio-group
          v-model="applicantFunctions.q5"
          :options="optionsq05"
        />
      </b-form-group>
    </div>
    <div class="mb-1 border p-1">
      <b-form-group>
        <label>Daha önce yaptığım işlerde; bana bağlı olarak çalışan birden fazla çalışan ya da birim bulunuyordu. Bu çalışanların iş emirleri vermek ve işlerin sonuçlarını takip etmek gibi görevler yapardım.</label>
        <b-form-radio-group
          v-model="applicantFunctions.q6"
          :options="optionsq06"
        />
      </b-form-group>
    </div>
  </div>
</template>
<script>
import {
  BFormGroup, BFormRadioGroup, // BFormInput, BRow, BCol, BFormTextarea
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  components: {
    BFormRadioGroup,
    // BFormTextarea,
    // BFormInput,
    BFormGroup,
    // BRow,
    // BCol,
  },
  directives: {
    Ripple,
  },
  props: {
    applicantId: {
      type: Number,
      required: false,
      default: -1,
    },
  },
  data() {
    return {
      optionsq01: [
        { html: '<p style="font-size:12px;">Böyle bir görev yapmadım.</p>', value: '1', disabled: false },
        { html: '<p style="font-size:12px;">Çok nadir bu görevleri yaptım.</p>', value: '2', disabled: false },
        { html: '<p style="font-size:12px;">Düşük düzeyde bu tip görevlerim oldu.</p>', value: '3', disabled: false },
        { html: '<p style="font-size:12px;">Orta düzeyde bu tip görevlerim oldu.</p>', value: '4', disabled: false },
        { html: '<p style="font-size:12px;">Yüksek düzeyde bu tip görevlerim oldu.</p>', value: '5', disabled: false },
      ],
      optionsq02: [
        { html: '<p style="font-size:12px;">Böyle bir görev yapmadım.</p>', value: '1', disabled: false },
        { html: '<p style="font-size:12px;">Çok nadir bu görevleri yaptım.</p>', value: '2', disabled: false },
        { html: '<p style="font-size:12px;">Düşük düzeyde bu tip görevlerim oldu.</p>', value: '3', disabled: false },
        { html: '<p style="font-size:12px;">Orta düzeyde bu tip görevlerim oldu.</p>', value: '4', disabled: false },
        { html: '<p style="font-size:12px;">Yüksek düzeyde bu tip görevlerim oldu.</p>', value: '5', disabled: false },
      ],
      optionsq03: [
        { html: '<p style="font-size:12px;">Böyle bir görev yapmadım.</p>', value: '1', disabled: false },
        { html: '<p style="font-size:12px;">Çok nadir bu görevleri yaptım.</p>', value: '2', disabled: false },
        { html: '<p style="font-size:12px;">Düşük düzeyde bu tip görevlerim oldu.</p>', value: '3', disabled: false },
        { html: '<p style="font-size:12px;">Orta düzeyde bu tip görevlerim oldu.</p>', value: '4', disabled: false },
        { html: '<p style="font-size:12px;">Yüksek düzeyde bu tip görevlerim oldu.</p>', value: '5', disabled: false },
      ],
      optionsq04: [
        { html: '<p style="font-size:12px;">Böyle bir görev yapmadım.</p>', value: '1', disabled: false },
        { html: '<p style="font-size:12px;">Çok nadir bu görevleri yaptım.</p>', value: '2', disabled: false },
        { html: '<p style="font-size:12px;">Düşük düzeyde bu tip görevlerim oldu.</p>', value: '3', disabled: false },
        { html: '<p style="font-size:12px;">Orta düzeyde bu tip görevlerim oldu.</p>', value: '4', disabled: false },
        { html: '<p style="font-size:12px;">Yüksek düzeyde bu tip görevlerim oldu.</p>', value: '5', disabled: false },
      ],
      optionsq05: [
        { html: '<p style="font-size:12px;">Böyle bir görev yapmadım.</p>', value: '1', disabled: false },
        { html: '<p style="font-size:12px;">Çok nadir bu görevleri yaptım.</p>', value: '2', disabled: false },
        { html: '<p style="font-size:12px;">Düşük düzeyde bu tip görevlerim oldu.</p>', value: '3', disabled: false },
        { html: '<p style="font-size:12px;">Orta düzeyde bu tip görevlerim oldu.</p>', value: '4', disabled: false },
        { html: '<p style="font-size:12px;">Yüksek düzeyde bu tip görevlerim oldu.</p>', value: '5', disabled: false },
      ],
      optionsq06: [
        { html: '<p style="font-size:12px;">Böyle bir görev yapmadım.</p>', value: '1', disabled: false },
        { html: '<p style="font-size:12px;">Çok nadir bu görevleri yaptım.</p>', value: '2', disabled: false },
        { html: '<p style="font-size:12px;">Düşük düzeyde bu tip görevlerim oldu.</p>', value: '3', disabled: false },
        { html: '<p style="font-size:12px;">Orta düzeyde bu tip görevlerim oldu.</p>', value: '4', disabled: false },
        { html: '<p style="font-size:12px;">Yüksek düzeyde bu tip görevlerim oldu.</p>', value: '5', disabled: false },
      ],
    //  selectedq01: '1',
    //  selectedq02: '1',
    //  selectedq03: '1',
    //  selectedq04: '1',
    //  selectedq05: '1',
    //  selectedq06: '1',
    }
  },
  computed: {
    ...mapGetters({
      applicant: 'applicantInfo/GET_APPLICANT',
      applicantFunctions: 'applicantInfo/GET_APPLICANTFUNCTIONS',
    }),
  },
  watch: {
    applicantId() {
      this.getApplicantData()
    },
  },
  mounted() {
    // this.getApplicantData()
  },
  methods: {
    ...mapMutations({
      setApplicant: 'applicantInfo/SET_APPLICANT',
      setApplicantFunctions: 'applicantInfo/SET_APPLICANTFUNCTIONS',
    }),
    ...mapActions({
      fetchApplicant: 'applicantInfo/action_get_applicant',
      fetchapplicantFunctions: 'applicantInfo/action_get_applicantFunctions',
    }),
    getApplicantData() {
      if (this.applicantId > 0) {
        this.fetchApplicant(this.applicantId)
        this.fetchapplicantFunctions(this.applicantId)
      }
    },
  },
}
</script>
