import request from '@/utils/request'

export function GetApplicantForeignLangByApplicantId(data) {
  return request({
    url: `/api/ApplicantForeignLangs/GetApplicantForeignLangByApplicantId/${data}`,
    method: 'get',
  })
}

export function GetApplicantForeignLangById(data) {
  return request({
    url: `/api/ApplicantForeignLangs/GetApplicantForeignLangById/${data}`,
    method: 'get',
  })
}

export function AddApplicantForeignLang(data) {
  return request({
    url: '/api/ApplicantForeignLangs/AddApplicantForeignLang',
    method: 'post',
    data,
  })
}

export function UpdateApplicantForeignLang(data) {
  return request({
    url: '/api/ApplicantForeignLangs/UpdateApplicantForeignLang',
    method: 'put',
    data,
  })
}

export function DeleteApplicantForeignLang(data) {
  return request({
    url: `/api/ApplicantForeignLangs/DeleteApplicantForeignLang?applicantForeignLangId=${data}&hardDelete=true`,
    method: 'delete',
  })
}

/*
        [HttpGet("GetApplicantForeignLangs")]
        [HttpGet("GetApplicantForeignLangById/{id}")]
        [HttpGet("GetApplicantForeignLangByApplicantId/{applicantId}")]
        [HttpPost("AddApplicantForeignLang")]
        [HttpPut("UpdateApplicantForeignLang")]
        [HttpDelete("DeleteApplicantForeignLang")]
*/
