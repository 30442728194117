import { render, staticRenderFns } from "./adayreferans.vue?vue&type=template&id=65cd2b02&"
import script from "./adayreferans.vue?vue&type=script&lang=js&"
export * from "./adayreferans.vue?vue&type=script&lang=js&"
import style0 from "./adayreferans.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports