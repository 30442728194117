<template>
  <div>
    <tabBaslik :applicant-id="applicantId" />
    <br><br>
    <b-tabs>
      <b-tab>
        <template #title>
          <feather-icon icon="MapPinIcon" />
          <span> Adres </span>
        </template>
        <tab-adres :applicant-id="applicantId" />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="ShieldIcon" />
          <span> Kişisel Özellikler </span>
        </template>
        <tab-kisisel :applicant-id="applicantId" />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="Edit3Icon" />
          <span> Mezuniyet </span>
        </template>
        <tab-mezun :applicant-id="applicantId" />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="ToolIcon" />
          <span> Mesleki Tecrübe</span>
        </template>
        <tab-meslek :applicant-id="applicantId" />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="FeatherIcon" />
          <span> Fonksiyonel Yeterlilik </span>
        </template>
        <tab-fonk :applicant-id="applicantId" />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="SquareIcon" />
          <span> Risk Alma </span>
        </template>
        <tab-risk :applicant-id="applicantId" />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="DollarSignIcon" />
          <span> Ücret ve Sosyal Haklar </span>
        </template>
        <tab-ucret :applicant-id="applicantId" />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="HeartIcon" />
          <span> Hobi ve İlgi Alanları </span>
        </template>
        <tab-hobi :applicant-id="applicantId" />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="UserPlusIcon" />
          <span> Referans </span>
        </template>
        <tab-refs :applicant-id="applicantId" />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="AwardIcon" />
          <span> Temel Yetenek </span>
        </template>
        <tab-temel :applicant-id="applicantId" />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="AwardIcon" />
          <span> Teknik Yeterlilik </span>
        </template>
        <tab-teknik :applicant-id="applicantId" />
      </b-tab>
      <b-tab
        v-if="userData.UserGroupId<3"
      >
        <template #title>
          <feather-icon icon="EditIcon" />
          <span> Notlar </span>
        </template>
        <tab-nots :applicant-id="applicantId" />
      </b-tab>
      <b-tab
        v-if="userData.UserGroupId<3"
      >
        <template #title>
          <feather-icon icon="PenToolIcon" />
          <span> Uzman Görüşleri </span>
        </template>
        <tab-uzman :applicant-id="applicantId" />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import tabBaslik from '@/views/islemler/aday/adaybaslik.vue'
import tabAdres from '@/views/islemler/aday/adayadres.vue'
import tabKisisel from '@/views/islemler/aday/adaykisisel.vue'
import tabMezun from '@/views/islemler/aday/adayMezun.vue'
import tabMeslek from '@/views/islemler/aday/adaymesleki.vue'
import tabFonk from '@/views/islemler/aday/adayfonksiyon.vue'
import tabRisk from '@/views/islemler/aday/adayrisk.vue'
import tabUcret from '@/views/islemler/aday/adayucret.vue'
import tabHobi from '@/views/islemler/aday/adayhobi.vue'
import tabRefs from '@/views/islemler/aday/adayreferans.vue'
import tabTemel from '@/views/islemler/aday/adaytemel.vue'
import tabNots from '@/views/islemler/aday/adaynots.vue'
import tabUzman from '@/views/islemler/aday/adayuzman.vue'
import tabTeknik from '@/views/islemler/aday/adayTeknik.vue'
import * as Auth from '@/utils/auth'
import { mapMutations } from 'vuex'

export default {
  components: {
    BTabs,
    BTab,
    tabBaslik,
    tabAdres,
    tabKisisel,
    tabMezun,
    tabMeslek,
    tabFonk,
    tabRisk,
    tabUcret,
    tabHobi,
    tabRefs,
    tabTemel,
    tabNots,
    tabUzman,
    tabTeknik,
  },
  data() {
    return {
      applicantId: this.$route.params.applicantId,
      userData: Auth.getUserObject(),
    }
  },
  beforeMount() {
    this.resetAdayId()
  },
  mounted() {
    this.resetAdayId()
  },
  methods: {
    ...mapMutations({
      resetAdayId: 'applicantInfo/RESET_ADAYID',
    }),
  },
}
</script>
