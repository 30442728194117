<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="3"
      >
        <b-form-group
          label="Tahsil Seviyesi"
          label-for="educationLevel"
        >
          <v-select
            id="educationLevel"
            v-model="educationLevel"
            label="educationLevelDescription"
            :options="optionTahsil"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
        md="2"
      >
        <b-form-group
          label="Meslek Lisesi"
          label-for="eml"
        >
          <v-select
            id="eml"
            v-model="VocationHighSchool"
            label="vocationalHighSchoolDepartmentName"
            :options="optionEML"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="3"
      >
        <b-form-group
          label="Ülke"
          label-for="ulke"
        >
          <v-select
            id="ulke"
            v-model="VocationHighSchoolCountry"
            label="countryName"
            :options="optionCountry"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="3"
      >
        <b-form-group
          label="Okul"
          label-for="okul"
        >
          <b-form-input
            id="okul"
            v-model="appEducation.vocationHighSchoolName"
            placeholder="Meslek Lisenizi yazınız"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="2"
      >
        <b-form-group
          label="Mezuniyet Yılı"
          label-for="mYil"
        >
          <b-form-input
            id="mYil"
            v-model="appEducation.vocationHighSchoolYearGrad"
            placeholder="Mezuniyet yılınız"
            type="number"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="2"
      >
        <b-form-group
          label="Mezuniyet Puanı"
          label-for="mPuan"
        >
          <b-form-input
            id="mPuan"
            v-model="appEducation.vocationHighSchoolPoint"
            placeholder="Mezuniyet puanınız"
            type="number"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
        md="2"
      >
        <b-form-group
          label="Meslek Yüksek Okulu"
          label-for="myo"
        >
          <v-select
            id="myo"
            v-model="VocationCollege"
            label="associateDegreeName"
            :options="optionMYO"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="3"
      >
        <b-form-group
          label="Ülke"
          label-for="ulke"
        >
          <v-select
            id="ulke"
            v-model="VocationCollegeCountry"
            label="countryName"
            :options="optionCountry"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="3"
      >
        <b-form-group
          label="Okul"
          label-for="myookul"
        >
          <b-form-input
            id="myookul"
            v-model="appEducation.vocationCollegeName"
            placeholder="Meslek Yüksek Okulunuzu yazınız"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="2"
      >
        <b-form-group
          label="Mezuniyet Yılı"
          label-for="mYOYil"
        >
          <b-form-input
            id="mYOYil"
            v-model="appEducation.vocationCollegeYearGrad"
            placeholder="Mezuniyet yılınız"
            type="number"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="2"
      >
        <b-form-group
          label="Mezuniyet Puanı"
          label-for="mYOPuan"
        >
          <b-form-input
            id="mYOPuan"
            v-model="appEducation.vocationCollegePoint"
            placeholder="Mezuniyet puanınız"
            type="number"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
        md="2"
      >
        <b-form-group
          label="Üniversite"
          label-for="unv"
        >
          <v-select
            id="unv"
            v-model="University"
            label="bachelorDegreeName"
            :options="optionUNV"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="3"
      >
        <b-form-group
          label="Ülke"
          label-for="unvulke"
        >
          <v-select
            id="unvulke"
            v-model="UniversityCountry"
            label="countryName"
            :options="optionCountry"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="3"
      >
        <b-form-group
          label="Okul"
          label-for="unvokul"
        >
          <b-form-input
            id="unvokul"
            v-model="appEducation.universityName"
            placeholder="Üniversitenizi yazınız"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="2"
      >
        <b-form-group
          label="Mezuniyet Yılı"
          label-for="mUnvYil"
        >
          <b-form-input
            id="mUnvYil"
            v-model="appEducation.universityYearGrad"
            placeholder="Mezuniyet yılınız"
            type="number"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="2"
      >
        <b-form-group
          label="Mezuniyet Puanı"
          label-for="UnvPuan"
        >
          <b-form-input
            id="UnvPuan"
            v-model="appEducation.universityPoint"
            placeholder="Mezuniyet puanınız"
            type="number"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
        md="2"
      >
        <b-form-group
          label="Lisans Üstü"
          label-for="dr"
        >
          <v-select
            id="dr"
            v-model="Postgraduate"
            label="postgraduateName"
            :options="optionPost"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="3"
      >
        <b-form-group
          label="Ülke"
          label-for="ulke"
        >
          <v-select
            id="ulke"
            v-model="PostgraduateCountry"
            label="countryName"
            :options="optionCountry"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="3"
      >
        <b-form-group
          label="Okul"
          label-for="drokul"
        >
          <b-form-input
            id="drokul"
            v-model="appEducation.postgraduateName"
            placeholder="Lisansüstü Okulunuzu yazınız"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="2"
      >
        <b-form-group
          label="Mezuniyet Yılı"
          label-for="drYil"
        >
          <b-form-input
            id="drYil"
            v-model="appEducation.postgraduateYearGrad"
            placeholder="Mezuniyet yılınız"
            type="number"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="2"
      >
        <b-form-group
          label="Mezuniyet Puanı"
          label-for="drPuan"
        >
          <b-form-input
            id="drPuan"
            v-model="appEducation.postgraduatePoint"
            placeholder="Mezuniyet puanınız"
            type="number"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BRow, BCol, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import * as apiTahsil from '@/api/tanimlar/VKT/educationLevels'
import * as apiEML from '@/api/tanimlar/VocationalHighSchoolDepartment'
import * as apiUlke from '@/api/tanimlar/ulke'
import * as apiMYO from '@/api/tanimlar/AssociateDegree'
import * as apiUNV from '@/api/tanimlar/BachelorDegree'
import * as apiPOST from '@/api/tanimlar/Postgraduate'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  components: {
    BRow, BCol, vSelect, BFormGroup, BFormInput,
  },
  directives: {
    Ripple,
  },
  props: {
    applicantId: {
      type: Number,
      required: false,
      default: -1,
    },
  },
  data() {
    return {
      optionTahsil: [],
      optionEML: [],
      optionCountry: [],
      optionMYO: [],
      optionUNV: [],
      optionPost: [],

      VocationHighSchool: {},
      educationLevel: {},
      VocationHighSchoolCountry: {},
      VocationCollege: {},
      VocationCollegeCountry: {},
      University: {},
      UniversityCountry: {},
      Postgraduate: {},
      PostgraduateCountry: {},
    }
  },
  computed: {
    ...mapGetters({
      applicant: 'applicantInfo/GET_APPLICANT',
      appEducation: 'applicantInfo/GET_APPLICANTEDUCATION',
    }),
  },
  watch: {
    VocationHighSchool() {
      this.appEducation.vocationHighSchool = this.VocationHighSchool.vocationalHighSchoolDepartmentName
    },
    educationLevel() {
      if (this.educationLevel == null) this.appEducation.educationLevelId = 1
      else this.appEducation.educationLevelId = this.educationLevel.educationLevelId
    },
    VocationHighSchoolCountry() {
      this.appEducation.vocationHighSchoolCountry = this.VocationHighSchoolCountry.countryName
    },
    VocationCollege() {
      this.appEducation.vocationCollege = this.VocationCollege.associateDegreeName
    },
    VocationCollegeCountry() {
      this.appEducation.vocationCollegeCountry = this.VocationCollegeCountry.countryName
    },
    University() {
      this.appEducation.university = this.University.bachelorDegreeName
    },
    UniversityCountry() {
      this.appEducation.universityCountry = this.UniversityCountry.countryName
    },
    Postgraduate() {
      this.appEducation.Postgraduate = this.Postgraduate.postgraduateName
    },
    PostgraduateCountry() {
      this.appEducation.postgraduateCountry = this.PostgraduateCountry.countryName
    },
    applicantId() {
      this.getApplicantData()
    },
  },
  created() {
    this.getApplicantData()
    this.getTahsil()
    this.getEml()
    this.getCountries()
    this.getMYO()
    this.getUNV()
    this.getPost()
  },
  methods: {
    ...mapMutations({
      setApplicant: 'applicantInfo/SET_APPLICANT',
      SetApplicanteducation: 'applicantInfo/SET_APPLICANTEDUCATION',
    }),
    ...mapActions({
      fetchApplicant: 'applicantInfo/action_get_applicant',
      fetchApplicantEdu: 'applicantInfo/action_get_applicantEducation',
    }),
    getApplicantData() {
      if (this.applicantId > 0) {
        this.fetchApplicant(this.applicantId)
        this.fetchApplicantEdu(this.applicantId).then(() => {
          if (this.appEducation.applicantEId > 0) {
            this.VocationHighSchool = { vocationalHighSchoolDepartmentName: this.appEducation.vocationHighSchool }
            this.VocationHighSchoolCountry = { countryName: this.appEducation.vocationHighSchoolCountry }
            this.VocationCollege = { associateDegreeName: this.appEducation.vocationCollege }
            this.VocationCollegeCountry = { countryName: this.appEducation.vocationCollegeCountry }
            this.University = { bachelorDegreeName: this.appEducation.university }
            this.UniversityCountry = { countryName: this.appEducation.universityCountry }
            this.PostgraduateCountry = { countryName: this.appEducation.postgraduateCountry }
            this.Postgraduate = { postgraduateName: this.appEducation.postgraduate }
            this.educationLevel = this.appEducation.educationLevel
          }
        })
      }
    },

    getTahsil() {
      apiTahsil.GetEducations().then(res => {
        this.optionTahsil = res.data.list
      })
    },
    getEml() {
      apiEML.getVocationalHighSchoolDepartments().then(res => {
        this.optionEML = res.data.list
      })
    },
    getCountries() {
      apiUlke.getCountries().then(res => {
        this.optionCountry = res.data.list
      })
    },
    getMYO() {
      apiMYO.getAssociateDegree().then(res => {
        this.optionMYO = res.data.list
      })
    },
    getUNV() {
      apiUNV.getBachelorDegrees().then(res => {
        this.optionUNV = res.data.list
      })
    },
    getPost() {
      apiPOST.getPostgraduates().then(res => {
        this.optionPost = res.data.list
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
