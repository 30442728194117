import request from '@/utils/request'

export function GetApplicantReferences() {
  return request({
    url: '/api/ApplicantReferences/GetApplicantReferences',
    method: 'get',
  })
}

export function GetApplicantReferencesById(data) {
  return request({
    url: `/api/ApplicantReferences/GetApplicantReferenceById/${data}`,
    method: 'get',
  })
}

export function GetApplicantReferencesByApplicantId(data) {
  return request({
    url: `/api/ApplicantReferences/GetApplicantReferenceByApplicantId/${data}`,
    method: 'get',
  })
}

export function AddApplicantReferences(data) {
  return request({
    url: '/api/ApplicantReferences/AddApplicantReference',
    method: 'post',
    data,
  })
}

export function UpdateApplicantReferences(data) {
  return request({
    url: '/api/ApplicantReferences/UpdateApplicantReference',
    method: 'put',
    data,
  })
}
export function DeleteApplicantReferences(data) {
  return request({
    url: `/api/ApplicantReferences/DeleteApplicantReference?applicantReferenceId=${data}&hardDelete=true`,
    method: 'delete',
  })
}
