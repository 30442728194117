import request from '@/utils/request'

export function GetApplicantComputers() {
  return request({
    url: '/api/ApplicantComputers/GetApplicantComputers',
    method: 'get',
  })
}

export function GetApplicantComputerById(data) {
  return request({
    url: `/api/ApplicantComputers/GetApplicantComputerById/${data}`,
    method: 'get',
  })
}

export function GetApplicantComputerByApplicantId(data) {
  return request({
    url: `/api/ApplicantComputers/GetApplicantComputerByApplicantId/${data}`,
    method: 'get',
  })
}

export function AddApplicantComputer(data) {
  return request({
    url: '/api/ApplicantComputers/AddApplicantComputer',
    method: 'post',
    data,
  })
}

export function UpdateApplicantComputer(data) {
  return request({
    url: '/api/ApplicantComputers/UpdateApplicantComputer',
    method: 'put',
    data,
  })
}

export function DeleteApplicantComputer(data) {
  return request({
    url: `/api/ApplicantComputers/DeleteApplicantComputer?applicantComputerId=${data}&hardDelete=true`,
    method: 'delete',
  })
}
