<template>
  <div>
    <b-form>
      <b-container>
        <b-row class="mb-1">
          <b-col
            cols="6"
            md="2"
          >
            <label>Sayısal Yetenek: </label>
          </b-col>
          <b-col
            cols="6"
            md="4"
          >
            <v-select
              id="numericalAbility"
              v-model="numericalAbility"
              class="small"
              label="technicalBasicCompetencyDescription"
              :options="optionYetenek"
            />
          </b-col>
          <!-- <b-col
            cols="6"
            md="2"
          >
            <label
              v-if="userData.UserGroupId<3"
            >Puan</label>
          </b-col>
          <b-col
            cols="6"
            md="4"
          >
            <b-form-input
              v-if="userData.UserGroupId<3"
              id="numericalPoint"
              v-model="abilities.numericalPoint"
              placeholder="Puan"
              type="number"
            />
          </b-col> -->
        </b-row>
        <b-row class="mb-1">
          <b-col
            cols="6"
            md="2"
          >
            <label>Analitik Düşünme: </label>
          </b-col>
          <b-col
            cols="6"
            md="4"
          >
            <v-select
              id="analyticalThinkingAbility"
              v-model="analyticalThinkingAbility"
              class="small"
              label="technicalBasicCompetencyDescription"
              :options="optionYetenek"
            />
          </b-col>
          <!-- <b-col
            cols="6"
            md="2"
          >
            <label
              v-if="userData.UserGroupId<3"
            >Puan</label>
          </b-col>
          <b-col
            cols="6"
            md="4"
          >
            <b-form-input
              v-if="userData.UserGroupId<3"
              id="analyticalThinkingPoint"
              v-model="abilities.analyticalThinkingPoint"
              placeholder="Puan"
              type="number"
            />
          </b-col> -->
        </b-row>
        <b-row class="mb-1">
          <b-col
            cols="6"
            md="2"
          >
            <label>Dikkat Yeteneği: </label>
          </b-col>
          <b-col
            cols="6"
            md="4"
          >
            <v-select
              id="attentionAbility"
              v-model="attentionAbility"
              class="small"
              label="technicalBasicCompetencyDescription"
              :options="optionYetenek"
            />
          </b-col>
          <!-- <b-col
            cols="6"
            md="2"
          >
            <label
              v-if="userData.UserGroupId<3"
            >Puan</label>
          </b-col>
          <b-col
            cols="6"
            md="4"
          >
            <b-form-input
              v-if="userData.UserGroupId<3"
              id="attentionPoint"
              v-model="abilities.attentionPoint"
              placeholder="Puan"
              type="number"
            />

          </b-col> -->
        </b-row>
        <b-row class="mb-1">
          <b-col
            cols="6"
            md="2"
          >
            <label>Öğrenebilme: </label>
          </b-col>
          <b-col
            cols="6"
            md="4"
          >
            <v-select
              id="learnabilityAbility"
              v-model="learnabilityAbility"
              class="small"
              label="technicalBasicCompetencyDescription"
              :options="optionYetenek"
            />
          </b-col>
          <!-- <b-col
            cols="6"
            md="2"
          >
            <label
              v-if="userData.UserGroupId<3"
            >Puan</label>
          </b-col>
          <b-col
            cols="6"
            md="4"
          >
            <b-form-input
              v-if="userData.UserGroupId<3"
              id="learnabilityPoint"
              v-model="abilities.learnabilityPoint"
              placeholder="Puan"
              type="number"
            />
          </b-col> -->
        </b-row>
        <b-row class="mb-1">
          <b-col
            cols="6"
            md="2"
          >
            <label>Öğrendiğini Uygulayabilme: </label>
          </b-col>
          <b-col
            cols="6"
            md="4"
          >
            <v-select
              id="applyingAbility"
              v-model="applyingAbility"
              class="small"
              label="technicalBasicCompetencyDescription"
              :options="optionYetenek"
            />
          </b-col>
          <!-- <b-col
            cols="6"
            md="2"
          >
            <label
              v-if="userData.UserGroupId<3"
            >Puan</label>
          </b-col>
          <b-col
            cols="6"
            md="4"
          >
            <b-form-input
              v-if="userData.UserGroupId<3"
              id="applyingAbilityPoint"
              v-model="abilities.applyingAbilityPoint"
              placeholder="Puan"
              type="number"
            />
          </b-col> -->
        </b-row>
        <b-row class="mb-1">
          <b-col
            cols="6"
            md="2"
          >
            <label>Yenilik ve Değişime Açıklık: </label>
          </b-col>
          <b-col
            cols="6"
            md="4"
          >
            <v-select
              id="innovationAbility"
              v-model="innovationAbility"
              class="small"
              label="technicalBasicCompetencyDescription"
              :options="optionYetenek"
            />
          </b-col>
          <!--
          <b-col
            cols="6"
            md="2"
          >
            <label
              v-if="userData.UserGroupId<3"
            >Puan</label>
          </b-col>
          <b-col
            cols="6"
            md="4"
          >
            <b-form-input
              v-if="userData.UserGroupId<3"
              id="innovationPoint"
              v-model="abilities.innovationPoint"
              placeholder="Puan"
              type="number"
            />
          </b-col> -->
        </b-row>
        <b-row class="mb-1">
          <b-col
            cols="6"
            md="2"
          >
            <label>İletişim ve Kendini Geliştirme: </label>
          </b-col>
          <b-col
            cols="6"
            md="4"
          >
            <v-select
              id="communicationAbility"
              v-model="communicationAbility"
              class="small"
              label="technicalBasicCompetencyDescription"
              :options="optionYetenek"
            />
          </b-col>
          <!--
          <b-col
            cols="6"
            md="2"
          >
            <label
              v-if="userData.UserGroupId<3"
            >Puan</label>
          </b-col>
          <b-col
            cols="6"
            md="4"
          >
            <b-form-input
              v-if="userData.UserGroupId<3"
              id="communicationPoint"
              v-model="abilities.communicationPoint"
              placeholder="Puan"
              type="number"
            />
          </b-col> -->
        </b-row>
        <b-row class="mb-1">
          <b-col
            cols="6"
            md="2"
          >
            <label>Problem Çözme ve Karar Verme: </label>
          </b-col>
          <b-col
            cols="6"
            md="4"
          >
            <v-select
              id="solutionAbility"
              v-model="solutionAbility"
              class="small"
              label="technicalBasicCompetencyDescription"
              :options="optionYetenek"
            />
          </b-col>
          <!--
          <b-col
            cols="6"
            md="2"
          >
            <label
              v-if="userData.UserGroupId<3"
            >Puan</label>
          </b-col>
          <b-col
            cols="6"
            md="4"
          >
            <b-form-input
              v-if="userData.UserGroupId<3"
              id="solutionPoint"
              v-model="abilities.solutionPoint"
              placeholder="Puan"
              type="number"
            />
          </b-col> -->
        </b-row>
      </b-container>
    </b-form>
  </div>
</template>
<script>
import {
  BRow, BCol, BForm, BContainer, // BButton,  BModal, BForm, VBModal, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import * as apiYetenek from '@/api/tanimlar/VKT/temelyetenek'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import * as Auth from '@/utils/auth'

export default {
  components: {
    BRow, BCol, vSelect, BForm, BContainer, // BButton,  BModal, BForm, BFormGroup, BFormInput,
  },
  directives: {
    Ripple,
  },
  props: {
    applicantId: {
      type: Number,
      required: false,
      default: -1,
    },
  },
  data() {
    return {
      optionYetenek: [],
      numericalAbility: {},
      analyticalThinkingAbility: {},
      learnabilityAbility: {},
      innovationAbility: {},
      communicationAbility: {},
      solutionAbility: {},
      applyingAbility: {},
      attentionAbility: {},
      userData: Auth.getUserObject(),
    }
  },
  computed: {
    ...mapGetters({
      applicant: 'applicantInfo/GET_APPLICANT',
      abilities: 'applicantInfo/GET_APPLICANTABILITIES',
    }),
  },
  watch: {
    applicantId() {
      this.getApplicant()
    },
    numericalAbility() {
      if (this.numericalAbility === null) return
      this.abilities.numericalAbilityId = this.numericalAbility.technicalBasicCompetencyId
    },
    analyticalThinkingAbility() {
      if (this.analyticalThinkingAbility === null) return
      this.abilities.analyticalThinkingAbilityId = this.analyticalThinkingAbility.technicalBasicCompetencyId
    },
    learnabilityAbility() {
      if (this.learnabilityAbility === null) return
      this.abilities.learnabilityAbilityId = this.learnabilityAbility.technicalBasicCompetencyId
    },
    innovationAbility() {
      if (this.innovationAbility === null) return
      this.abilities.innovationAbilityId = this.innovationAbility.technicalBasicCompetencyId
    },
    communicationAbility() {
      if (this.communicationAbility === null) return
      this.abilities.communicationAbilityId = this.communicationAbility.technicalBasicCompetencyId
    },
    solutionAbility() {
      if (this.solutionAbility === null) return
      this.abilities.solutionAbilityId = this.solutionAbility.technicalBasicCompetencyId
    },
    applyingAbility() {
      if (this.applyingAbility === null) return
      this.abilities.applyingAbilityId = this.applyingAbility.technicalBasicCompetencyId
    },
    attentionAbility() {
      if (this.attentionAbility === null) return
      this.abilities.attentionAbilityId = this.attentionAbility.technicalBasicCompetencyId
    },
  },
  created() {
    this.getYetenek()
    this.getApplicant()
  },
  methods: {
    ...mapMutations({
      setApplicant: 'applicantInfo/SET_APPLICANT',
      setAbilities: 'applicantInfo/SET_APPLICANTABILITIES',
    }),
    ...mapActions({
      fetchApplicant: 'applicantInfo/action_get_applicant',
      fetchAbilities: 'applicantInfo/action_get_applicantAbilities',
    }),
    getYetenek() {
      apiYetenek.GetTechnicalBasicCompetencies().then(res => {
        this.optionYetenek = res.data.list
      })
    },
    getApplicant() {
      if (this.applicantId > 0) {
        this.fetchApplicant(this.applicantId)
        this.getAbility()
      }
    },
    getAbility() {
      if (this.applicantId > 0) {
        this.fetchAbilities(this.applicantId).then(() => {
          this.numericalAbility = this.abilities.numericalAbility
          this.analyticalThinkingAbility = this.abilities.analyticalThinkingAbility
          this.learnabilityAbility = this.abilities.learnabilityAbility
          this.innovationAbility = this.abilities.innovationAbility
          this.communicationAbility = this.abilities.communicationAbility
          this.solutionAbility = this.abilities.solutionAbility
          this.applyingAbility = this.abilities.applyingAbility
          this.attentionAbility = this.abilities.attentionAbility
        })
      }
    },
  },
}
</script>
