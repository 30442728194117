import request from '@/utils/request'

export function GetKnowledgeLevels() {
  return request({
    url: '/api/KnowledgeLevels/GetKnowledgeLevels',
    method: 'get',
  })
}

export function GetKnowledgeLevelById(data) {
  return request({
    url: `/api/KnowledgeLevels/GetKnowledgeLevelById/${data}`,
    method: 'get',
  })
}
