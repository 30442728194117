import request from '@/utils/request'

export function GetApplicantHobbies() {
  return request({
    url: '/api/ApplicantHobbies/GetApplicantHobbies',
    method: 'get',
  })
}

export function GetApplicantHobbiesById(data) {
  return request({
    url: `/api/ApplicantHobbies/GetApplicantHobbiesById/${data}`,
    method: 'get',
  })
}

export function GetApplicantHobbiesByApplicantId(data) {
  return request({
    url: `/api/ApplicantHobbies/GetApplicantHobbiesByApplicantId/${data}`,
    method: 'get',
  })
}

export function AddApplicantHobbies(data) {
  return request({
    url: '/api/ApplicantHobbies/AddApplicantHobbies',
    method: 'post',
    data,
  })
}

export function UpdateApplicantHobbies(data) {
  return request({
    url: '/api/ApplicantHobbies/UpdateApplicantHobbies',
    method: 'put',
    data,
  })
}
export function DeleteApplicantHobbies(data) {
  return request({
    url: `/api/ApplicantHobbies/DeleteApplicantHobbies?applicantHobbieId=${data}&hardDelete=true`,
    method: 'delete',
  })
}

/*
        [HttpGet("GetApplicantHobbies")]
        [HttpGet("GetApplicantHobbiesById/{id}")]
        [HttpGet("GetApplicantHobbiesByApplicantId/{applicantId}")]
        [HttpPost("AddApplicantHobbies")]
        [HttpPut("UpdateApplicantHobbies")]
        [HttpDelete("DeleteApplicantHobbies")]
*/
