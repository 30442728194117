<template>
  <div>
    <b-form>
      <b-container>
        <b-row class="mb-1">
          <b-col
            cols="6"
            md="2"
          >
            <label>Planlama Yeteneği: </label>
          </b-col>
          <b-col
            cols="6"
            md="4"
          >
            <v-select
              id="PlanningAbility"
              v-model="planningAbility"
              class="small"
              label="technicalBasicCompetencyDescription"
              :options="optionYetenek"
            />
          </b-col>
          <!-- <b-col
            cols="6"
            md="2"
          >
            <label
              v-if="userData.UserGroupId<3"
            >Puan</label>
          </b-col>
          <b-col
            cols="6"
            md="4"
          >
            <b-form-input
              v-if="userData.UserGroupId<3"
              id="planningPoint"
              v-model="abilities.planningPoint"
              placeholder="Puan"
              type="number"
            />
          </b-col> -->
        </b-row>
        <b-row class="mb-1">
          <b-col
            cols="6"
            md="2"
          >
            <label>Koordinasyon : </label>
          </b-col>
          <b-col
            cols="6"
            md="4"
          >
            <v-select
              id="coordinationAbility"
              v-model="coordinationAbility"
              class="small"
              label="technicalBasicCompetencyDescription"
              :options="optionYetenek"
            />
          </b-col>
          <!-- <b-col
            cols="6"
            md="2"
          >
            <label
              v-if="userData.UserGroupId<3"
            >Puan</label>
          </b-col>
          <b-col
            cols="6"
            md="4"
          >
            <b-form-input
              v-if="userData.UserGroupId<3"
              id="coordinationPoint"
              v-model="abilities.coordinationPoint"
              placeholder="Puan"
              type="number"
            />
          </b-col> -->
        </b-row>
        <b-row class="mb-1">
          <b-col
            cols="6"
            md="2"
          >
            <label>Emir Komuta: </label>
          </b-col>
          <b-col
            cols="6"
            md="4"
          >
            <v-select
              id="obeyingAbility"
              v-model="obeyingAbility"
              class="small"
              label="technicalBasicCompetencyDescription"
              :options="optionYetenek"
            />
          </b-col>
          <!-- <b-col
            cols="6"
            md="2"
          >
            <label
              v-if="userData.UserGroupId<3"
            >Puan</label>
          </b-col>
          <b-col
            cols="6"
            md="4"
          >
            <b-form-input
              v-if="userData.UserGroupId<3"
              id="obeyingPoint"
              v-model="abilities.obeyingPoint"
              placeholder="Puan"
              type="number"
            />

          </b-col> -->
        </b-row>
        <b-row class="mb-1">
          <b-col
            cols="6"
            md="2"
          >
            <label>Tasarım Yeteneği: </label>
          </b-col>
          <b-col
            cols="6"
            md="4"
          >
            <v-select
              id="designAbility"
              v-model="designAbility"
              class="small"
              label="technicalBasicCompetencyDescription"
              :options="optionYetenek"
            />
          </b-col>
          <!-- <b-col
            cols="6"
            md="2"
          >
            <label
              v-if="userData.UserGroupId<3"
            >Puan</label>
          </b-col>
          <b-col
            cols="6"
            md="4"
          >
            <b-form-input
              v-if="userData.UserGroupId<3"
              id="designPoint"
              v-model="abilities.designPoint"
              placeholder="Puan"
              type="number"
            />
          </b-col> -->
        </b-row>
        <b-row class="mb-1">
          <b-col
            cols="6"
            md="2"
          >
            <label>Temsil Yeteneği: </label>
          </b-col>
          <b-col
            cols="6"
            md="4"
          >
            <v-select
              id="representationAbility"
              v-model="representationAbility"
              class="small"
              label="technicalBasicCompetencyDescription"
              :options="optionYetenek"
            />
          </b-col>
          <!--
          <b-col
            cols="6"
            md="2"
          >
            <label
              v-if="userData.UserGroupId<3"
            >Puan</label>
          </b-col>
          <b-col
            cols="6"
            md="4"
          >
            <b-form-input
              v-if="userData.UserGroupId<3"
              id="representationPoint"
              v-model="abilities.representationPoint"
              placeholder="Puan"
              type="number"
            />
          </b-col> -->
        </b-row>
        <b-row class="mb-1">
          <b-col
            cols="6"
            md="2"
          >
            <label>Kotrol ve Denetim : </label>
          </b-col>
          <b-col
            cols="6"
            md="4"
          >
            <v-select
              id="controllingAbility"
              v-model="controllingAbility"
              class="small"
              label="technicalBasicCompetencyDescription"
              :options="optionYetenek"
            />
          </b-col>
          <!--
          <b-col
            cols="6"
            md="2"
          >
            <label
              v-if="userData.UserGroupId<3"
            >Puan</label>
          </b-col>
          <b-col
            cols="6"
            md="4"
          >
            <b-form-input
              v-if="userData.UserGroupId<3"
              id="controllingPoint"
              v-model="abilities.controllingPoint"
              placeholder="Puan"
              type="number"
            />
          </b-col> -->
        </b-row>
      </b-container>
    </b-form>
  </div>
</template>
<script>
import {
  BRow, BCol, BForm, BContainer, // BButton,  BModal, BForm, VBModal, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import * as apiYetenek from '@/api/tanimlar/VKT/temelyetenek'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import * as Auth from '@/utils/auth'
// import * as apiApAbilities from '@/api/islemler/applicant/applicantAbility'

export default {
  components: {
    BRow, BCol, vSelect, BForm, BContainer, // BButton,  BModal, BForm, BFormGroup, BFormInput,
  },
  directives: {
    Ripple,
  },
  props: {
    applicantId: {
      type: Number,
      required: false,
      default: -1,
    },
  },
  data() {
    return {
      optionYetenek: [],
      planningAbility: {},
      coordinationAbility: {},
      obeyingAbility: {},
      designAbility: {},
      representationAbility: {},
      controllingAbility: {},
      userData: Auth.getUserObject(),
    }
  },
  computed: {
    ...mapGetters({
      applicant: 'applicantInfo/GET_APPLICANT',
      abilities: 'applicantInfo/GET_APPLICANTABILITIES',
    }),
  },
  watch: {
    applicantId() {
      this.getApplicant()
    },
    planningAbility() {
      if (this.planningAbility === null) return
      this.abilities.planningAbilityId = this.planningAbility.technicalBasicCompetencyId
    },
    coordinationAbility() {
      if (this.coordinationAbility === null) return
      this.abilities.coordinationAbilityId = this.coordinationAbility.technicalBasicCompetencyId
    },
    obeyingAbility() {
      if (this.obeyingAbility === null) return
      this.abilities.obeyingAbilityId = this.obeyingAbility.technicalBasicCompetencyId
    },
    designAbility() {
      if (this.designAbility === null) return
      this.abilities.designAbilityId = this.designAbility.technicalBasicCompetencyId
    },
    representationAbility() {
      if (this.representationAbility === null) return
      this.abilities.representationAbilityId = this.representationAbility.technicalBasicCompetencyId
    },
    controllingAbility() {
      if (this.controllingAbility === null) return
      this.abilities.controllingAbilityId = this.controllingAbility.technicalBasicCompetencyId
    },

  },
  created() {
    this.getYetenek()
    this.getApplicant()
  },
  methods: {
    ...mapMutations({
      setApplicant: 'applicantInfo/SET_APPLICANT',
      setAbilities: 'applicantInfo/SET_APPLICANTABILITIES',
    }),
    ...mapActions({
      fetchApplicant: 'applicantInfo/action_get_applicant',
      fetchAbilities: 'applicantInfo/action_get_applicantAbilities',
    }),
    getYetenek() {
      apiYetenek.GetTechnicalBasicCompetencies().then(res => {
        this.optionYetenek = res.data.list
      })
    },
    getApplicant() {
      if (this.applicantId > 0) {
        this.fetchApplicant(this.applicantId)
        this.getAbility()
      }
    },
    getAbility() {
      if (this.applicantId > 0) {
        this.fetchAbilities(this.applicantId).then(() => {
          this.planningAbility = this.abilities.planningAbility
          this.coordinationAbility = this.abilities.coordinationAbility
          this.obeyingAbility = this.abilities.obeyingAbility
          this.designAbility = this.abilities.designAbility
          this.representationAbility = this.abilities.representationAbility
          this.controllingAbility = this.abilities.controllingAbility
        })
      }
    },
  },
}
</script>
