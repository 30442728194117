import request from '@/utils/request'

export function GetApplicantCertificate() {
  return request({
    url: '/api/ApplicantCertificates/GetApplicantCertificate',
    method: 'get',
  })
}

export function GetApplicantCertificateById(data) {
  return request({
    url: `/api/ApplicantCertificates/GetApplicantCertificateById/${data}`,
    method: 'get',
  })
}

export function GetApplicantCertificateByApplicantId(data) {
  return request({
    url: `/api/ApplicantCertificates/GetApplicantCertificateByApplicantId/${data}`,
    method: 'get',
  })
}

export function AddApplicantCertificate(data) {
  return request({
    url: '/api/ApplicantCertificates/AddApplicantCertificate',
    method: 'post',
    data,
  })
}

export function UpdateApplicantCertificate(data) {
  return request({
    url: '/api/ApplicantCertificates/UpdateApplicantCertificate',
    method: 'put',
    data,
  })
}

export function DeleteApplicantCertificate(data) {
  return request({
    url: `/api/ApplicantCertificates/DeleteApplicantCertificate?applicantCertificateId=${data}&hardDelete=true`,
    method: 'delete',
  })
}

/*
        [HttpGet("GetApplicantCertificate")]
        [HttpGet("GetApplicantCertificateById/{id}")]
        [HttpGet("GetApplicantCertificateByApplicantId/{applicantId}")]
        [HttpPost("AddApplicantCertificate")]
        [HttpPut("UpdateApplicantCertificate")]
        [HttpDelete("DeleteApplicantCertificate")]
*/
