import request from '@/utils/request'

// api/ReferenceTypes/GetReferenceTypes
export function GetReferenceTypes() {
  return request({
    url: '/api/ReferenceTypes/GetReferenceTypes',
    method: 'get',
  })
}

export function GetReferenceTypeById(data) {
  return request({
    url: `/api/ReferenceTypes/GetReferenceTypeById/${data}`,
    method: 'get',
  })
}
