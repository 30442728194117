<template>
  <div>
    <b-row>
      <b-col>
        <b-form-group
          label="Çalışılmak istenilen Pozisyon 1"
          label-for="poz1"
        >
          <b-form-input
            id="poz1"
            v-model="applicantExperiences.position1"
            placeholder="Pozisyon giriniz"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          v-if="userData.UserGroupId<3"
          label="Faktör 1"
          label-for="fk1"
        >
          <v-select
            id="fk1"
            v-model="applicantExperiences.position1Factor"
            label="staffPositionName"
            :options="optionPOZ"
            :reduce="item => item.staffPositionName"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group
          label="Çalışılmak istenilen Pozisyon 2"
          label-for="poz2"
        >
          <b-form-input
            id="poz2"
            v-model="applicantExperiences.position2"
            placeholder="Pozisyon giriniz"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          v-if="userData.UserGroupId<3"
          label="Faktör 2"
          label-for="fk2"
        >
          <v-select
            id="fk2"
            v-model="applicantExperiences.position2Factor"
            label="staffPositionName"
            :options="optionPOZ"
            :reduce="item => item.staffPositionName"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group
          label="Çalışılmak istenilen Pozisyon 3"
          label-for="poz3"
        >
          <b-form-input
            id="poz3"
            v-model="applicantExperiences.position3"
            placeholder="Pozisyon giriniz"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          v-if="userData.UserGroupId<3"
          label="Faktör 3"
          label-for="fk3"
        >
          <v-select
            id="fk3"
            v-model="applicantExperiences.position3Factor"
            label="staffPositionName"
            :options="optionPOZ"
            :reduce="item => item.staffPositionName"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group
          label="Çalışılmak istenilen Pozisyon 4"
          label-for="poz4"
        >
          <b-form-input
            id="poz4"
            v-model="applicantExperiences.position4"
            placeholder="Pozisyon giriniz"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          v-if="userData.UserGroupId<3"
          label="Faktör 4"
          label-for="fk4"
        >
          <v-select
            id="fk4"
            v-model="applicantExperiences.position4Factor"
            label="staffPositionName"
            :options="optionPOZ"
            :reduce="item => item.staffPositionName"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group
          label="Çalışılmak istenilen Pozisyon 5"
          label-for="poz5"
        >
          <b-form-input
            id="poz5"
            v-model="applicantExperiences.position5"
            placeholder="Pozisyon giriniz"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          v-if="userData.UserGroupId<3"
          label="Faktör 5"
          label-for="fk5"
        >
          <v-select
            id="fk5"
            v-model="applicantExperiences.position5Factor"
            label="staffPositionName"
            :options="optionPOZ"
            :reduce="item => item.staffPositionName"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group
          label="Toplam Tecrübe Yılı"
          label-for="tyil"
        >
          <b-form-input
            id="tyil"
            v-model="applicantExperiences.experienceYear"
            placeholder="Toplam Tecrübe yılınızı giriniz"
            type="number"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          v-if="userData.UserGroupId<3"
          label="Faktör"
          label-for="fk"
        >
          <v-select
            id="fk"
            v-model="experienceYearFactor"
            label="experienceYearTime"
            :options="optionGerek"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="mb-1">
      <b-col>
        <sub-meslek1 :applicant-id="applicantId" />
      </b-col>
    </b-row>
    <b-row class="mb-1">
      <b-col>
        <sub-meslek2 :applicant-id="applicantId" />
      </b-col>
    </b-row>
    <b-row class="mb-1">
      <b-col>
        <sub-meslek3 :applicant-id="applicantId" />
      </b-col>
    </b-row>
    <b-row class="mb-1">
      <b-col>
        <sub-meslek4 :applicant-id="applicantId" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, // BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import * as apiPOZ from '@/api/tanimlar/personelpozisyon'
import * as apiGerek from '@/api/tanimlar/VKT/Experience'
import * as Auth from '@/utils/auth'

import subMeslek1 from '@/views/islemler/aday/adayMeslek1.vue'
import subMeslek2 from '@/views/islemler/aday/adayMeslek2.vue'
import subMeslek3 from '@/views/islemler/aday/adayMeslek3.vue'
import subMeslek4 from '@/views/islemler/aday/adayMeslek4.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  components: {
    subMeslek1, subMeslek2, subMeslek3, subMeslek4, vSelect, BRow, BCol, BFormGroup, BFormInput, // BButton, VueGoodTable,
  },
  directives: {
    Ripple,
  },
  props: {
    applicantId: {
      type: Number,
      required: false,
      default: -1,
    },
  },
  data() {
    return {
      optionPOZ: [],
      optionGerek: [],
      userData: Auth.getUserObject(),
      experienceYearFactor: {},
    }
  },
  computed: {
    ...mapGetters({
      applicant: 'applicantInfo/GET_APPLICANT',
      applicantExperiences: 'applicantInfo/GET_APPLICANTEXPERIENCES',
      getAdayId: 'applicantInfo/GET_ADAYID',
    }),
  },
  watch: {
    applicantId() {
      this.getApplicantData()
    },
    experienceYearFactor() {
      if (this.experienceYearFactor === null) return
      this.applicantExperiences.experienceYearFactorId = this.experienceYearFactor.experienceYearId
    },
  },
  created() {
    this.getPozisyon()
    this.getGerek()
    this.getApplicantData()
  },
  methods: {
    ...mapMutations({
      setApplicant: 'applicantInfo/SET_APPLICANT',
      setApplicantExperiences: 'applicantInfo/SET_APPLICANTEXPERIENCES',
      setAdayId: 'applicantInfo/SET_ADAYID',
    }),
    ...mapActions({
      fetchApplicant: 'applicantInfo/action_get_applicant',
      fetchApplicantExperiences: 'applicantInfo/action_get_applicantExperiences',
    }),
    getApplicantData() {
      if (this.applicantId > 0) {
        this.fetchApplicant(this.applicantId)
        this.fetchApplicantExperiences(this.applicantId).then(() => {
          this.experienceYearFactor = this.applicantExperiences.experienceYearFactor
        })
      }
    },

    getPozisyon() {
      apiPOZ.GetPersonelPozisyonList().then(res => {
        this.optionPOZ = res.data.list
      })
    },
    getGerek() {
      apiGerek.GetExpYears().then(res => {
        this.optionGerek = res.data.list
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
// @import '@core/scss/vue/libs/vue-good-table.scss';
</style>
