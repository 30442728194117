<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group
          label="Adı ve Soyadı"
          label-for="appName"
        >
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="UserIcon" />
            </b-input-group-prepend>
            <b-form-input
              id="appName"
              v-model="applicant.applicantName"
              placeholder="Aday Adı Soyadı"
            />
          </b-input-group>
        </b-form-group>
        <b-form-group
          label="T.C. Kimlik No"
          label-for="appTCKN"
        >
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="InfoIcon" />
            </b-input-group-prepend>
            <b-form-input
              id="appTCKN"
              v-model="applicant.applicantTCKN"
              placeholder="Aday T.C. Kimlik No"
              maxlength="11"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group
          label="E-Posta Adresi"
          label-for="appEmail"
        >
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="UserIcon" />
            </b-input-group-prepend>
            <b-form-input
              id="appEmail"
              v-model="applicant.applicantEmail"
              placeholder="Aday e-posta adresi"
            />
          </b-input-group>
        </b-form-group>
        <b-form-group
          label="Telefonu"
          label-for="appPhone"
        >
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="InfoIcon" />
            </b-input-group-prepend>
            <b-form-input
              id="appPhone"
              v-model="applicant.applicantPhone"
              placeholder="Aday Telefon Numarası"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col>
        <b-col
          cols="12"
          md="12"
        >
          <b-row class="mb-1">
            <b-col>
              <div class="demo-inline-spacing">
                <b-form-radio
                  v-model="applicant.status"
                  name="some-radio9"
                  value="1"
                  class="custom-control-success"
                  :disabled="userObj.UserGroupId == 4 && applicant.status == 2"
                >
                  Aktif
                </b-form-radio>
                <b-form-radio
                  v-model="applicant.status"
                  name="some-radio9"
                  value="2"
                  class="custom-control-secondary"
                >
                  Pasif
                </b-form-radio>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <label for="appLogo">Aday resmi</label>
            <b-form-file
              id="appLogo"
              v-model="adayResim"
              accept=".jpg, .png, .gif"
              placeholder="Resim seçiniz."
              browse-text="Gözat"
              @input="filesizecheck()"
            />
          </b-row>
          <b-row class="mt-1 mb-1">
            <img
              id="blah"
              :src="applicant.photo === '' ? require('@/assets/images/usernoImage.png') : applicant.photo"
              width="100px"
              alt="Aday Resim"
            >
          </b-row>
          <b-row>
            <b-button
              v-if="applicant.authUserId === null"
              id="userButton"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              class="d-flex align-items-center"
              squared
              variant="success"
              @click="OnAddUserClick()"
            >
              <feather-icon
                icon="UserPlusIcon"
                class="mr-50"
              />
              <span>Hesap Oluştur</span>
            </b-button>
            &nbsp;
            <b-button
              ref="btnKaydet"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              class="d-flex align-items-center"
              squared
              variant="danger"
              @click="OnAddApplicantClick()"
            >
              <feather-icon
                icon="SaveIcon"
                class="mr-50"
              />
              <span>Aday Kaydet</span>
            </b-button>
             &nbsp;
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              class="d-flex align-items-center"
              squared
              variant="warning"
              @click="ViewCVClick()"
            >
              <feather-icon
                icon="SaveIcon"
                class="mr-50"
              />
              <span>CV Göster</span>
            </b-button>
          </b-row>
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BInputGroup, BInputGroupPrepend, BButton, BFormFile, BFormRadio,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import * as apiUser from '@/api/auth'
import * as auth from '@/utils/auth'
import * as apiApplicant from '@/api/islemler/applicant/applicantInfo'

// import * as apiAppl from '@/api/islemler/applicant/applicantInfo'
// import jspdf from 'jspdf'

export default {
  components: {
    BRow, BCol, BFormGroup, BFormInput, BInputGroup, BInputGroupPrepend, BButton, BFormFile, BFormRadio,
  },
  directives: {
    Ripple,
  },
  props: {
    // applicantId: {
    //   type: Number,
    //   required: false,
    //   default: -1,
    // },
  },
  data() {
    return {
      // applicant: [],
      adayResim: [],
      adayuser: {},
      userObj: {},
      applicantId: this.$route.params.applicantId,
    }
  },
  computed: {
    ...mapGetters({
      applicant: 'applicantInfo/GET_APPLICANT',
      get_ApplicantPicture: 'applicantInfo/GET_APPLICANTPICTURE',
      getAdayId: 'applicantInfo/GET_ADAYID',
    }),
  },
  watch: {
    applicantId() {
      this.getApplicantData()
    },
  },
  beforeCreate() {
    this.userObj = auth.getUserObject()
    apiApplicant.GetApplicantByUserId(this.userObj.UserId).then(res => {
      if (res.data.entity !== null) {
        this.applicantId = res.data.entity.applicantId
      }
    })
  },
  mounted() {
    this.getApplicantData()
    this.userObj = auth.getUserObject()
  },
  methods: {
    ...mapMutations({
      setApplicant: 'applicantInfo/SET_APPLICANT',
    }),
    ...mapActions({
      fetchApplicant: 'applicantInfo/action_get_applicant',
      saveApplicant: 'applicantInfo/action_save_applicant',
      saveApplicantEducation: 'applicantInfo/action_save_applicantEducation',
      saveApplicantAbilities: 'applicantInfo/action_save_applicantAbilities',
      saveApplicantFunctions: 'applicantInfo/action_save_applicantFunctions',
      saveApplicantExperiences: 'applicantInfo/action_save_applicantExperiences',
      setPicture: 'applicantInfo/action_set_applicant_picture',
      resetApplicant: 'applicantInfo/action_reset_applicant',
    }),
    getApplicantData() {
      if (this.applicantId > 0) {
        this.fetchApplicant(this.applicantId)
        this.$refs.btnKaydet.removeAttribute('disabled')
      } else {
        this.resetApplicant()
      }
    },
    OnAddApplicantClick() {
      if (this.applicantId === 0 || this.applicantId === undefined) {
        this.$refs.btnKaydet.setAttribute('disabled', '1')
      }
      if (this.userObj.UserId !== 0 && this.userObj.userGroupId === 4) {
        this.applicant.authUserId = this.userObj.UserId
      }
      this.saveApplicant().then(resp => {
        const applicantId = resp
        this.applicantId = applicantId
        // this.setAdayId(applicantId)
        this.saveApplicantEducation(applicantId).then(() => {
          this.saveApplicantExperiences(applicantId).then(() => {
            this.saveApplicantFunctions(applicantId).then(() => {
              this.saveApplicantAbilities(applicantId).then(() => {
                apiApplicant.CheckValidation(applicantId).then()
              })
            })
          })
        })
        this.$refs.btnKaydet.removeAttribute('disabled')
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Aday Bilgileri',
            text: 'Kayıt tamamlandı.',
            icon: 'PlusSquareIcon',
            variant: 'success',
          },
        })
      })
    },
    filesizecheck() {
      const boyut = this.adayResim.size / 1024
      if (boyut <= 300) {
        const reader = new FileReader()
        reader.onloadend = () => {
          this.applicant.photo = reader.result
        }
        reader.readAsDataURL(this.adayResim)
      }
    },
    OnAddUserClick() {
      const bosluk = this.applicant.applicantName.split(' ')
      const Soyad = bosluk[bosluk.length - 1]
      const Ad = this.applicant.applicantName.substring(0, this.applicant.applicantName.indexOf(' '))
      const userGroup = {
        username: this.applicant.applicantEmail,
        password: this.applicant.applicantTCKN,
        groupId: 4,
      }
      const auser = {
        username: this.applicant.applicantEmail,
        password: this.applicant.applicantTCKN,
        firstName: Ad,
        middleName: '',
        lastName: Soyad,
        eMail: this.applicant.applicantEmail,
        gsm: this.applicant.applicantPhone,
      }
      apiUser.signup(auser).then(res => { // login oluştu
        if (res.data.entity > 0) {
          apiUser.assignUserGroup(userGroup).then(() => { // user gruba kayıt
            // E-posta atılacak
            this.applicant.authUserId = res.data.entity
            this.saveApplicant()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Aday Hesabı Oluşturma',
                text: 'Aday Hesabı oluşturuldu.',
                icon: 'PlusSquareIcon',
                variant: 'success',
              },
            })
          })
        }
      })
    },
    ViewCVClick() {
      this.$router.push({ name: 'aday-ozgecmis', params: { applicant: this.applicant } })
      // window.open(routeData.href, '_blank')
    },
  },
}
</script>
