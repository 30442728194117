<template>
  <div>
    <b-row>
      <b-col>
        <div id="Hobi-grid-container">
          <b-form-group>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-modal.hobiadd-lg
              class="d-flex align-items-left"
              squared
              variant="relief-success"
              size="sm"
              :disabled="getAdayId < 0"
            >
              <feather-icon
                icon="DribbbleIcon"
                class="mr-50"
              />
              <span>Hobi Bilgisi Ekle</span>
            </b-button>
          </b-form-group>
        </div>
        <!-- table -->
        <vue-good-table
          :columns="hobiCols"
          :rows="hobiRows"
          :search-options="{
            enabled: false }"
          :pagination-options="{
            enabled: false }"
          theme="my-theme"
          size="sm"
          @on-row-click="onDataGridItemClicked"
        >
          <div slot="emptystate">
            <center> Aday hobi bilgisi kaydı bulunmuyor.</center>
          </div>
          <template
            slot="table-row"
            slot-scope="props"
          >
            <!-- Column: Action -->
            <span v-if="props.column.field === 'action'">
              <span>
                <template>
                  <b-form-group
                    class="mt-25"
                    size="sm"
                  >
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      size="sm"
                      squared
                      class="mr-1"
                      variant="outline-warning"
                      @click="onUpdateHobiBtnClicked(props.row)"
                    >
                      <feather-icon
                        icon="Edit2Icon"
                        class="mr-50"
                      />
                      <span>Düzenle</span>
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      size="sm"
                      squared
                      variant="outline-danger"
                      @click="onDeleteHobbyBtnClicked(props.row)"
                    >
                      <feather-icon
                        icon="TrashIcon"
                        class="mr-50"
                      />
                      <span>Sil</span>
                    </b-button>
                  </b-form-group>
                </template>
              </span>
            </span>
            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
        <b-modal
          id="hobiadd-lg"
          cancel-variant="outline-secondary"
          ok-title="Ekle"
          size="lg"
          cancel-title="İptal"
          centered
          no-close-on-backdrop
          style="height:700px;"
          title="Hobi Bilgisi Ekle"
          @show="onLoadSelects"
          @ok="onModalSaveHobi"
          @cancel="onModalCancelled"
        >
          <b-form>
            <b-row>
              <b-col>
                <b-form-group>
                  <label
                    for="applicantHOId"
                    hidden
                  >
                    Sıra No:</label>
                  <b-form-input
                    id="applicantHOId"
                    v-model="applicantHOId"
                    type="text"
                    placeholder="Sıra No"
                    hidden
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
              >
                <b-form-group>
                  <label for="hobbyName">Hobi Alanı:</label>
                  <b-form-input
                    id="hobbyName"
                    v-model="hobbyName"
                    class="small"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="6">
                <b-form-group>
                  <label for="factorHobby">Hobi Faktörü:</label>
                  <v-select
                    id="factorHobby"
                    v-model="factorHobby"
                    label="hobbyFactorName"
                    :options="optionFaktor"
                    :reduce="item => item.hobbyFactorName"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group>
                  <label for="hobbyTask">Gerektiğinde görev alır mı?</label>
                  <div class="demo-inline-spacing">
                    <b-form-radio
                      v-model="hobbyTask"
                      plain
                      value="E"
                    >
                      Evet
                    </b-form-radio>
                    <b-form-radio
                      v-model="hobbyTask"
                      plain
                      value="H"
                    >
                      Hayır
                    </b-form-radio>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </b-modal>
        <!-- Hobis Güncelle  MODAL -->
        <b-modal
          id="HobiUpd-modal"
          cancel-variant="outline-secondary"
          ok-title="Güncelle"
          cancel-title="İptal"
          size="lg"
          centered
          no-close-on-backdrop
          style="height:700px;"
          title="Hobi Alanı Güncelle"
          @ok="onModalUpdateHobi"
          @cancel="onModalCancelled"
          @show="onLoadSelects"
        >
          <b-form>
            <b-row>
              <b-col>
                <b-form-group>
                  <label
                    for="applicantHOId"
                    hidden
                  >
                    Sıra No:</label>
                  <b-form-input
                    id="applicantHOId"
                    v-model="applicantHOId"
                    type="text"
                    placeholder="Sıra No"
                    hidden
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
              >
                <b-form-group>
                  <label for="hobbyName">Hobi Alanı:</label>
                  <b-form-input
                    id="hobbyName"
                    v-model="hobbyName"
                    class="small"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="6">
                <b-form-group>
                  <label for="factorHobby">Hobi Faktörü:</label>
                  <v-select
                    id="factorHobby"
                    v-model="factorHobby"
                    label="hobbyFactorName"
                    :options="optionFaktor"
                    :reduce="item => item.hobbyFactorName"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group>
                  <label for="hobbyTask">Gerektiğinde görev alır mı?</label>
                  <div class="demo-inline-spacing">
                    <b-form-radio
                      v-model="hobbyTask"
                      plain
                      value="E"
                    >
                      Evet
                    </b-form-radio>
                    <b-form-radio
                      v-model="hobbyTask"
                      plain
                      value="H"
                    >
                      Hayır
                    </b-form-radio>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </b-modal>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BRow, BCol, BFormGroup, BButton, BFormInput, BModal, BForm, VBModal, BFormRadio,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { VueGoodTable } from 'vue-good-table'
import * as apiFaktor from '@/api/tanimlar/Hobbies'
import * as apiApHobi from '@/api/islemler/applicant/applicantHobbies'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  components: {
    VueGoodTable, BRow, BCol, BFormGroup, BButton, BFormInput, BModal, BForm, vSelect, BFormRadio,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  props: {
    applicantId: {
      type: Number,
      required: false,
      default: -1,
    },
  },
  data() {
    return {
      optionFaktor: [],
      optionAHobi: [],
      hobiCols: [
        {
          label: 'Sıra No',
          field: 'applicantHOId',
          hidden: true,
        },
        {
          label: 'Hobi Adı',
          field: 'hobbyName',
        },
        {
          label: 'Faktör',
          field: 'factorHobby',
        },
        {
          label: 'Görev Alırmı',
          field: this.taskLabel,
        },
        {
          label: 'İşlem',
          field: 'action',
          sortable: false,
          width: '15%',
        },
      ],
      hobiRows: [],
      applicantHOId: 0,
      appId: this.applicantId,
      hobbyName: '',
      factorHobby: { HobbyId: 0, hobbyFactorName: '' },
      hobbyTask: 'true',
    }
  },
  computed: {
    ...mapGetters({
      applicant: 'applicantInfo/GET_APPLICANT',
      getAdayId: 'applicantInfo/GET_ADAYID',
    }),
  },
  watch: {
    applicantId() {
      this.getApplicantData()
    },
    // getAdayId() {
    //   this.getApplicantData()
    // },
  },
  created() {
    this.getFaktors()
    this.getHobbies()
  },
  methods: {
    ...mapMutations({
      setApplicant: 'applicantInfo/SET_APPLICANT',
    }),
    ...mapActions({
      fetchApplicant: 'applicantInfo/action_get_applicant',
    }),
    taskLabel(rowObj) {
      let retval = ''
      if (rowObj.hobbyTask === true) {
        retval = 'Evet'
      } else {
        retval = 'Hayır'
      }
      return retval
    },
    getApplicantData() {
      if (this.getAdayId > 0) {
        this.fetchApplicant(this.getAdayId)
        this.appId = this.getAdayId
        this.applicantId = this.getAdayId
        this.getHobbies()
      }
    },

    getFaktors() {
      apiFaktor.getHobbies().then(res => {
        this.optionFaktor = res.data.list
      })
    },
    getHobbies() {
      apiApHobi.GetApplicantHobbiesByApplicantId(this.appId).then(res => {
        this.hobiRows = res.data.list
      })
    },
    clearModal() {
      this.applicantHOId = 0
      this.hobbyName = ''
      this.factorHobby = ''
    },
    onDataGridItemClicked(params) {
      return params
    },
    onModalSaveHobi() {
      const hobiModel = {
        applicantHOId: 0,
        applicantId: this.getAdayId,
        hobbyName: this.hobbyName,
        factorHobby: this.factorHobby,
        hobbyTask: this.hobbyTask,
      }
      if (this.hobbyTask === 'E') {
        hobiModel.hobbyTask = true
      } else {
        hobiModel.hobbyTask = false
      }
      apiApHobi.AddApplicantHobbies(hobiModel).then(() => {
        this.getHobbies()
        this.clearModal()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Hobi alanı ekle',
            text: 'Yeni kayıt eklendi.',
            icon: 'PlusSquareIcon',
            variant: 'success',
          },
        })
        this.clearModal()
        this.appId = this.getAdayId
        this.getHobbies()
      })
    },
    onModalCancelled() {
      this.clearModal()
    },
    onModalUpdateHobi() {
      const hobiModel = {
        applicantHOId: this.applicantHOId,
        applicantId: this.appId,
        hobbyName: this.hobbyName,
        factorHobby: this.factorHobby,
        hobbyTask: this.hobbyTask,
      }
      if (this.hobbyTask === 'E') {
        hobiModel.hobbyTask = true
      } else {
        hobiModel.hobbyTask = false
      }
      apiApHobi.UpdateApplicantHobbies(hobiModel).then(() => {
        this.getHobbies()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Hobi alanı güncelle',
            text: 'Kayıt güncellendi.',
            icon: 'EditIcon',
            variant: 'warning',
          },
        })
      })
      this.clearModal()
    },
    onUpdateHobiBtnClicked(row) {
      this.applicantHOId = row.applicantHOId
      this.hobbyName = row.hobbyName
      this.factorHobby = row.factorHobby
      this.hobbyTask = row.hobbyTask ? 'E' : 'H'
      this.$bvModal.show('HobiUpd-modal')
    },
    onDeleteHobbyBtnClicked(row) {
      this.$bvModal.msgBoxConfirm(` '${row.hobbyName}' adlı hobi bilgisi silinecek, onaylıyor musunuz?`, {
        title: 'Uyarı!',
        size: 'md',
        buttonSize: 'md',
        okVariant: 'danger',
        okTitle: 'Evet',
        cancelTitle: 'Hayır',
        footerClass: 'p-2',
        hideHeaderClose: true,
        noCloseOnBackdrop: true,
        centered: true,
      })
        .then(value => {
          if (value) {
            apiApHobi.DeleteApplicantHobbies(row.applicantHOId).then(() => {
              this.getHobbies()
            })
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Kayıt Silindi',
                text: `${row.hobbyName} adlı hobi bilgisi kaydı silindi.`,
                icon: 'DeleteIcon',
                variant: 'danger',
              },
            })
          }
        })
      this.clearModal()
    },
    onLoadSelects() {
      this.getFaktors()
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
