<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="3"
        class="p-1"
      >
        <label>Daha önce işlerimde mali risk aldım.</label>
      </b-col>
      <b-col
        cols="12"
        md="5"
        class="p-1"
      >
        <v-select
          id="financialRiskLevel"
          v-model="financialRiskLevel"
          class="small"
          label="riskLevelDescription"
          :options="optionRisk"
        />
      </b-col>
      <b-col
        cols="12"
        md="4"
        class="p-1"
      >
        <b-form-checkbox
          v-model="appFunctions.financialRiskNewJob"
        >
          Yeni işimde mali risk alırım.
        </b-form-checkbox>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
        md="3"
        class="p-1"
      >
        <label>Daha önce işlerimde hukuki risk aldım.</label>
      </b-col>
      <b-col
        cols="12"
        md="5"
        class="p-1"
      >
        <v-select
          id="legalRiskLevel"
          v-model="legalRiskLevel"
          class="small"
          label="riskLevelDescription"
          :options="optionRisk"
        />
      </b-col>
      <b-col
        cols="12"
        md="4"
        class="p-1"
      >
        <b-form-checkbox
          v-model="appFunctions.legalRiskNewJob"
        >
          Yeni işimde hukuki risk alırım.
        </b-form-checkbox>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
        md="3"
        class="p-1"
      >
        <label>Daha önce işlerimde sağlık riski aldım.</label>
      </b-col>
      <b-col
        cols="12"
        md="5"
        class="p-1"
      >
        <v-select
          id="medicalRiskLevel"
          v-model="medicalRiskLevel"
          class="small"
          label="riskLevelDescription"
          :options="optionRisk"
        />
      </b-col>
      <b-col
        cols="12"
        md="4"
        class="p-1"
      >
        <b-form-checkbox
          v-model="appFunctions.medicalRiskNewJob"
        >
          Yeni işimde sağlık riski alırım.
        </b-form-checkbox>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
        md="3"
        class="p-1"
      >
        <label>Daha önce işlerimde hayati risk aldım.</label>
      </b-col>
      <b-col
        cols="12"
        md="5"
        class="p-1"
      >
        <v-select
          id="LifeRiskLevel"
          v-model="lifeRiskLevel"
          class="small"
          label="riskLevelDescription"
          :options="optionRisk"
        />
      </b-col>
      <b-col
        cols="12"
        md="4"
        class="p-1"
      >
        <b-form-checkbox
          v-model="appFunctions.lifeRiskNewJob"
        >
          Yeni işimde hayati risk alırım.
        </b-form-checkbox>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BRow, BCol, BFormCheckbox, // BFormGroup, BButton, BFormInput, BModal, BForm, VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import * as apiRisk from '@/api/tanimlar/VKT/riskLevel'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  components: {
    BRow, BCol, vSelect, BFormCheckbox, // BFormGroup, BButton, BFormInput, BModal, BForm,
  },
  directives: {
    Ripple,
  },
  props: {
    applicantId: {
      type: Number,
      required: false,
      default: -1,
    },
  },
  data() {
    return {
      optionRisk: [],
      financialRiskLevel: {},
      legalRiskLevel: {},
      medicalRiskLevel: {},
      lifeRiskLevel: {},
    }
  },
  computed: {
    ...mapGetters({
      applicant: 'applicantInfo/GET_APPLICANT',
      appFunctions: 'applicantInfo/GET_APPLICANTFUNCTIONS',
    }),
  },
  watch: {
    applicantId() {
      this.getApplicantData()
    },
    financialRiskLevel() {
      if (this.financialRiskLevel === null) return
      this.appFunctions.financialRiskLevelId = this.financialRiskLevel.riskLevelId
      this.appFunctions.financialRiskLevel = null
    },
    legalRiskLevel() {
      if (this.legalRiskLevel === null) return
      this.appFunctions.legalRiskLevelId = this.legalRiskLevel.riskLevelId
      this.appFunctions.legalRiskLevel = null
    },
    medicalRiskLevel() {
      if (this.medicalRiskLevel === null) return
      this.appFunctions.medicalRiskLevelId = this.medicalRiskLevel.riskLevelId
      this.appFunctions.medicalRiskLevel = null
    },
    lifeRiskLevel() {
      if (this.lifeRiskLevel === null) return
      this.appFunctions.lifeRiskLevelId = this.lifeRiskLevel.riskLevelId
      this.appFunctions.lifeRiskLevel = null
    },
  },
  created() {
    this.getRisks()
  },
  mounted() {
    this.getApplicantData()
  },
  methods: {
    ...mapMutations({
      setApplicant: 'applicantInfo/SET_APPLICANT',
      setApplicantFunctions: 'applicantInfo/SET_APPLICANTFUNCTIONS',
    }),
    ...mapActions({
      fetchApplicant: 'applicantInfo/action_get_applicant',
      fetchAppFunctions: 'applicantInfo/action_get_applicantFunctions',
    }),
    getApplicantData() {
      if (this.applicantId > 0) {
        this.fetchApplicant(this.applicantId)
        this.fetchAppFunctions(this.applicantId).then(() => {
          this.financialRiskLevel = this.appFunctions.financialRiskLevel
          this.legalRiskLevel = this.appFunctions.legalRiskLevel
          this.medicalRiskLevel = this.appFunctions.medicalRiskLevel
          this.lifeRiskLevel = this.appFunctions.lifeRiskLevel
        })
      }
    },
    getRisks() {
      apiRisk.GetRisLevels().then(res => {
        this.optionRisk = res.data.list
      })
    },
  },
}
</script>
