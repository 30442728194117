<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group
          label="İli"
          label-for="appCity"
        >
          <v-select
            id="appCity"
            v-model="sehir"
            label="cityName"
            :options="optionCity"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group
          label="İlçe"
          label-for="appTown"
        >
          <v-select
            id="appTown"
            v-model="town"
            label="townName"
            :options="optionTown"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group
          label="Mahalle"
          label-for="appDisctrict"
        >
          <v-select
            id="appDistrict"
            v-model="district"
            label="neighborhoodName"
            :options="optionMah"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group
          label="Cadde / Sokak "
          label-for="street"
        >
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="MapIcon" />
            </b-input-group-prepend>
            <b-form-input
              id="street"
              v-model="applicant.street"
              placeholder="Cadde / Sokak giriniz"
              maxlength="25"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group
          label="Kapı No"
          label-for="doorNumber"
        >
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="MapPinIcon" />
            </b-input-group-prepend>
            <b-form-input
              id="doorNumber"
              v-model="applicant.doorNumber"
              placeholder="Kapı No giriniz"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group
          label="Açık Adres"
          label-for="addressDefinition"
        >
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="ShuffleIcon" />
            </b-input-group-prepend>
            <b-form-input
              id="addressDefinition"
              v-model="applicant.addressDefinition"
              placeholder="Açık Adres giriniz"
              size="sm"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BInputGroup, BInputGroupPrepend, // BButton, BFormFile,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import * as apiCity from '@/api/tanimlar/sehir'
import * as apiTown from '@/api/tanimlar/town'
import * as apiMah from '@/api/tanimlar/Neighborhood'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  components: {
    vSelect, BRow, BCol, BFormGroup, BFormInput, BInputGroup, BInputGroupPrepend, // BButton, BFormFile,
  },
  directives: {
    Ripple,
  },
  props: {
    applicantId: {
      type: Number,
      required: false,
      default: -1,
    },
  },
  data() {
    return {
      optionCity: [],
      optionTown: [],
      optionMah: [],
      sehir: {},
      town: {},
      district: {},

      // street: '',
      // doorNumber: '',
      // addressDefinition: '',
    }
  },
  computed: {
    ...mapGetters({
      applicant: 'applicantInfo/GET_APPLICANT',
    }),
  },
  watch: {
    applicantId() {
      this.getApplicantData()
    },
    sehir() {
      this.applicant.city = this.sehir.cityName
      this.getTown()
    },
    town() {
      this.townId = this.town.townId
      this.applicant.town = this.town.townName
      this.getDistricts()
    },
    district() {
      this.applicant.district = this.district.neighborhoodName
    },
  },
  created() {
  },
  mounted() {
    this.getCities()
    this.getApplicantData()
  },
  methods: {
    ...mapMutations({
      setApplicant: 'applicantInfo/SET_APPLICANT',
    }),
    ...mapActions({
      fetchApplicant: 'applicantInfo/action_get_applicant',
    }),
    getApplicantData() {
      if (this.applicantId > 0) {
        this.fetchApplicant(this.applicantId).then(() => {
          const cityname = this.applicant.city
          if (cityname.length > 0) {
            apiCity.getCityByCityName(this.applicant.city).then(res => {
              if (res.data.entity !== null) {
                this.sehir = res.data.entity
                this.getTown()
                if (this.applicant.town.length > 0) {
                  apiTown.GetTownByTownName(this.applicant.town).then(r => {
                    this.town = r.data.entity
                    apiMah.GetNeighborhoodsByTownIdNeighbourhoodName(this.town.townId, this.applicant.district).then(m => {
                      this.district = m.data.entity
                    })
                    this.getDistricts()
                  })
                }
              }
            })
          }
        })
      }
    },
    getCities() {
      apiCity.getCityByCountryId(1).then(res => {
        this.optionCity = res.data.list
      })
    },
    getTown() {
      apiTown.GetTownsByCityId(this.sehir.cityId).then(res => {
        this.optionTown = res.data.list
      })
    },
    getDistricts() {
      apiMah.getNeighborhoodsByTownId(this.town.townId).then(res => {
        this.optionMah = res.data.list
      })
    },
  },
}
</script>
<style lang='scss'>
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
