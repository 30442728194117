import request from '@/utils/request'

export function GetApplicantNotes() {
  return request({
    url: '/api/ApplicantNotes/GetApplicantNotes',
    method: 'get',
  })
}

export function GetApplicantNotesById(data) {
  return request({
    url: `/api/ApplicantNotes/GetApplicantNotesById/${data}`,
    method: 'get',
  })
}

export function GetApplicantNotesByApplicantId(data) {
  return request({
    url: `/api/ApplicantNotes/GetApplicantNotesByApplicantId/${data}`,
    method: 'get',
  })
}

export function AddApplicantNotes(data) {
  return request({
    url: '/api/ApplicantNotes/AddApplicantNotes',
    method: 'post',
    data,
  })
}

export function UpdateApplicantNotes(data) {
  return request({
    url: '/api/ApplicantNotes/UpdateApplicantNotes',
    method: 'put',
    data,
  })
}
export function DeleteApplicantNotes(data) {
  return request({
    url: `/api/ApplicantNotes/DeleteApplicantNotes?applicantHobbieId=${data}`,
    method: 'delete',
  })
}
