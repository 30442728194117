<template>
  <div>
    <b-row>
      <b-col>
        <div id="Cert-grid-container">
          <b-form-group>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-modal.certadd-lg
              class="d-flex align-items-left"
              squared
              variant="relief-dark"
              size="sm"
              :disabled="getAdayId < 0"
            >
              <feather-icon
                icon="AddIcon"
                class="mr-50"
              />
              <span>Sertifika Ekle</span>
            </b-button>
          </b-form-group>
        </div>
        <!-- table -->
        <vue-good-table
          :columns="certCols"
          :rows="certRows"
          :search-options="{
            enabled: false }"
          :pagination-options="{
            enabled: false }"
          theme="my-theme"
          @on-row-click="onDataGridItemClicked"
        >
          <div slot="emptystate">
            <center> Aday sertifika kaydı bulunmuyor.</center>
          </div>
          <template
            slot="table-row"
            slot-scope="props"
          >
            <!-- Column: Action -->
            <span v-if="props.column.field === 'action'">
              <span>
                <template>
                  <b-button-group
                    class="mt-25"
                    size="sm"
                  >
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      size="sm"
                      squared
                      class="mr-1"
                      variant="outline-warning"
                      @click="onUpdateCertBtnClicked(props.row)"
                    >
                      <feather-icon
                        icon="Edit2Icon"
                        class="mr-50"
                      />
                      <span>Düzenle</span>
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      size="sm"
                      squared
                      variant="outline-danger"
                      @click="onDeleteCertBtnClicked(props.row)"
                    >
                      <feather-icon
                        icon="TrashIcon"
                        class="mr-50"
                      />
                      <span>Sil</span>
                    </b-button>
                  </b-button-group>
                </template>
              </span>
            </span>
            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
        <b-modal
          id="certadd-lg"
          cancel-variant="outline-secondary"
          ok-title="Ekle"
          size="lg"
          cancel-title="İptal"
          centered
          no-close-on-backdrop
          style="height:700px;"
          title="Sertifika Ekle"
          @show="onLoadSelects"
          @ok="onModalSaveCert"
          @cancel="onModalCancelled"
        >
          <b-form>
            <b-row>
              <b-col>
                <b-form-group>
                  <label
                    for="queNumber"
                    hidden
                  >Sıra No:</label>
                  <b-form-input
                    id="queNumber"
                    v-model="queNumber"
                    type="text"
                    placeholder="Sıra No"
                    hidden
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group>
                  <label for="certificate">Sertifika Adı:</label>
                  <v-select
                    id="certificate"
                    v-model="certificate"
                    class="small"
                    label="certificateName"
                    :options="optionCert"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group>
                  <label for="courseName">Kurs adı/ Alındığı yer:</label>
                  <b-form-input
                    id="courseName"
                    v-model="courseName"
                    type="text"
                    placeholder="Kurs adı/ Alındığı yer"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group>
                  <label for="certificateYear">Sertifika Yılı:</label>
                  <b-form-input
                    id="certificateYear"
                    v-model="certificateYear"
                    type="number"
                    placeholder="Sertifika yılı"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group>
                  <label for="description">Açıklama:</label>
                  <b-form-input
                    id="description"
                    v-model="description"
                    type="text"
                    placeholder="Açıklama"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </b-modal>
        <!-- Sertifika Güncelle  MODAL -->
        <b-modal
          id="Cerguncelle-modal"
          cancel-variant="outline-secondary"
          ok-title="Güncelle"
          cancel-title="İptal"
          size="lg"
          centered
          no-close-on-backdrop
          title="Sertifika Güncelle"
          @ok="onModalUpdateCer"
          @cancel="onModalCancelled"
        >
          <b-form>
            <b-row>
              <b-col>
                <b-form-group hidden>
                  <label for="queNumber">Sıra No:</label>
                  <b-form-input
                    id="queNumber"
                    v-model="queNumber"
                    type="text"
                    placeholder="Sıra No"
                    disabled
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group>
                  <label for="certificate">Sertifika Adı:</label>
                  <v-select
                    id="certificate"
                    v-model="certificate"
                    label="certificateName"
                    :options="optionCert"
                    :reduce="item => item.certificateName"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group>
                  <label for="courseName">Kurs adı/ Alındığı yer:</label>
                  <b-form-input
                    id="courseName"
                    v-model="courseName"
                    type="text"
                    placeholder="Kurs adı/ Alındığı yer"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group>
                  <label for="certificateYear">Sertifika Yılı:</label>
                  <b-form-input
                    id="certificateYear"
                    v-model="certificateYear"
                    type="number"
                    placeholder="Sertifika yılı"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group>
                  <label for="description">Açıklama:</label>
                  <b-form-input
                    id="description"
                    v-model="description"
                    type="text"
                    placeholder="Açıklama"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </b-modal>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BRow, BCol, BFormGroup, BButton, BFormInput, BModal, BForm, BButtonGroup, VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { VueGoodTable } from 'vue-good-table'
import * as apiCert from '@/api/tanimlar/Certificate'
import * as apiApCert from '@/api/islemler/applicant/applicantCertificates'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    VueGoodTable, BRow, BCol, BFormGroup, BButton, BFormInput, BModal, BForm, BButtonGroup, vSelect,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  props: {
    applicantId: {
      type: Number,
      required: false,
      default: -1,
    },
  },
  data() {
    return {
      optionCert: [],
      certCols: [
        {
          label: 'Sıra No',
          field: 'queNumber',
          hidden: true,
        },
        {
          label: 'Sertifika',
          field: 'certificate',
        },
        {
          label: 'Kurs Adı / Alındığı Yer',
          field: 'courseName',
        },
        {
          label: 'Alındığı Yıl',
          field: 'certificateYear',
        },
        {
          label: 'Açıklama',
          field: 'description',
        },
        {
          label: 'İşlem',
          field: 'action',
          sortable: false,
          width: '15%',
        },
      ],
      certRows: [],
      queNumber: 1,
      certificate: '',
      courseName: '',
      certificateYear: 0,
      description: '',
      appId: this.applicantId,
    }
  },
  computed: {
    ...mapGetters({
      applicant: 'applicantInfo/GET_APPLICANT',
      getAdayId: 'applicantInfo/GET_ADAYID',
    }),
  },
  watch: {
    applicantId() {
      this.getApplicantData()
    },
  },
  created() {
    this.getApplicantData()
    this.getApCert()
    this.onLoadSelects()
  },
  methods: {
    ...mapMutations({
      setApplicant: 'applicantInfo/SET_APPLICANT',
    }),
    ...mapActions({
      fetchApplicant: 'applicantInfo/action_get_applicant',
    }),
    getApplicantData() {
      if (this.getAdayId > 0) {
        this.fetchApplicant(this.getAdayId)
        this.getApCert()
      }
    },
    getCert() {
      apiCert.getCertificates().then(res => {
        this.optionCert = res.data.list
      })
    },
    getApCert() {
      apiApCert.GetApplicantCertificateByApplicantId(this.appId).then(res => {
        this.certRows = res.data.list
        if (res.data.list == null) this.queNumber = 1
        else this.queNumber = res.data.count + 1
      })
    },
    clearModal() {
      this.queNumber = 0
      this.certificate = ''
      this.courseName = ''
      this.certificateYear = 0
      this.description = ''
      this.getApCert()
    },
    onDataGridItemClicked(params) {
      return params
    },
    onModalSaveCert() {
      const cerModel = {
        applicantCEId: 0,
        applicantId: this.getAdayId,
        queNumber: this.queNumber,
        certificate: this.certificate.certificateName,
        courseName: this.courseName,
        certificateYear: this.certificateYear,
        description: this.description,
      }
      apiApCert.AddApplicantCertificate(cerModel).then(() => {
        this.appId = this.getAdayId
        this.getApCert()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Kayıt Eklendi',
            text: 'Kayıt başarıyla eklendi',
            icon: 'EditIcon',
            variant: 'warning',
          },
        })
        this.appId = this.getAdayId
        this.getApCert()
      })
      this.clearModal()
    },
    onModalCancelled() {
      this.clearModal()
    },
    onModalUpdateCer() {
      const cerModel = {
        applicantCEId: this.applicantCEId,
        applicantId: this.getAdayId,
        queNumber: this.queNumber,
        certificate: this.certificate,
        courseName: this.courseName,
        certificateYear: this.certificateYear,
        description: this.description,
      }
      apiApCert.UpdateApplicantCertificate(cerModel).then(() => {
        this.getApCert()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Sertifika Bilgisi Güncelle',
            text: 'Kayıt Güncellendi',
            icon: 'EditIcon',
            variant: 'warning',
          },
        })
      })
      this.clearModal()
    },
    onUpdateCertBtnClicked(row) {
      this.getCert()
      this.onLoadSelects()
      this.applicantCEId = row.applicantCEId
      this.applicantId = row.applicantId
      this.queNumber = row.queNumber
      this.certificate = row.certificate // .certificateName
      this.courseName = row.courseName
      this.certificateYear = row.certificateYear
      this.description = row.description
      this.$bvModal.show('Cerguncelle-modal')
    },
    onDeleteCertBtnClicked(row) {
      this.$bvModal.msgBoxConfirm(` '${row.certificate}' sertifika bilgisi silinecek, onaylıyor musunuz?`, {
        title: 'Uyarı!',
        size: 'md',
        buttonSize: 'md',
        okVariant: 'danger',
        okTitle: 'Evet',
        cancelTitle: 'Hayır',
        footerClass: 'p-2',
        hideHeaderClose: true,
        noCloseOnBackdrop: true,
        centered: true,
      })
        .then(value => {
          if (value) {
            apiApCert.DeleteApplicantCertificate(row.applicantCEId).then(() => {
              this.getApCert()
            })
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Kayıt Silindi',
                text: `${row.certificate} sertifika bilgisi kaydı silindi.`,
                icon: 'DeleteIcon',
                variant: 'danger',
              },
            })
          }
        })
      this.clearModal()
    },
    onLoadSelects() {
      this.getCert()
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
