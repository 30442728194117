<template>
  <div>
    <b-row>
      <b-col>
        <div id="Ref-grid-container">
          <b-form-group>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-modal.refadd-lg
              class="d-flex align-items-left"
              squared
              variant="relief-warning"
              size="sm"
              :disabled="getAdayId<1"
            >
              <feather-icon
                icon="UsersIcon"
                class="mr-50"
              />
              <span>Referans Bilgisi Ekle</span>
            </b-button>
          </b-form-group>
        </div>
        <!-- table -->
        <vue-good-table
          :columns="refCols"
          :rows="refRows"
          :search-options="{
            enabled: false }"
          :pagination-options="{
            enabled: false }"
          theme="my-theme"
          @on-row-click="onDataGridItemClicked"
        >
          <div slot="emptystate">
            <center> Aday referans bilgisi kaydı bulunmuyor.</center>
          </div>
          <template
            slot="table-row"
            slot-scope="props"
          >
            <!-- Column: Action -->
            <span v-if="props.column.field === 'action'">
              <span>
                <template>
                  <b-button-group
                    class="mt-25"
                    size="sm"
                  >
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      size="sm"
                      squared
                      class="mr-1"
                      variant="outline-warning"
                      @click="onUpdateRefBtnClicked(props.row)"
                    >
                      <feather-icon
                        icon="Edit2Icon"
                        class="mr-50"
                      />
                      <span>Düzenle</span>
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      size="sm"
                      squared
                      variant="outline-danger"
                      @click="onDeleteRefBtnClicked(props.row)"
                    >
                      <feather-icon
                        icon="TrashIcon"
                        class="mr-50"
                      />
                      <span>Sil</span>
                    </b-button>
                  </b-button-group>
                </template>
              </span>
            </span>
            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
        <b-modal
          id="refadd-lg"
          cancel-variant="outline-secondary"
          ok-title="Ekle"
          size="lg"
          cancel-title="İptal"
          centered
          no-close-on-backdrop
          style="height:700px;"
          title="Referans Bilgisi Ekle"
          @ok="onModalSaveRef"
          @cancel="onModalCancelled"
        >
          <b-form>
            <b-row>
              <b-col>
                <b-form-group>
                  <label
                    for="applicantREFId"
                    hidden
                  >
                    Sıra No:</label>
                  <b-form-input
                    id="applicantREFId"
                    v-model="applicantREFId"
                    type="text"
                    placeholder="Sıra No"
                    hidden
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
              >
                <b-form-group>
                  <label for="referenceName">Adı Soyadı:</label>
                  <b-form-input
                    id="referenceName"
                    v-model="referenceName"
                    class="small"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
              >
                <b-form-group>
                  <label for="referenceEmail">Email:</label>
                  <b-form-input
                    id="referenceEmail"
                    v-model="referenceEmail"
                    class="small"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
              >
                <b-form-group>
                  <label for="referenceTelephone">Telefon:</label>
                  <b-form-input
                    id="referenceTelephone"
                    v-model="referenceTelephone"
                    class="small"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
              >
                <b-form-group>
                  <label for="referenceCompany">Kuruluş Adı:</label>
                  <b-form-input
                    id="referenceCompany"
                    v-model="referenceCompany"
                    class="small"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="6">
                <b-form-group>
                  <label for="referencePosition">Pozisyon:</label>
                  <v-select
                    id="referencePosition"
                    v-model="referencePosition"
                    label="staffPositionName"
                    :options="optionPoz"
                    :reduce="item => item.staffPositionName"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group>
                  <label for="referenceType">Referans Tipi: </label>
                  <v-select
                    id="referenceType"
                    v-model="referenceType"
                    label="referenceTypeDescription"
                    :options="optionRefType"
                    :reduce="item => item.referenceTypeDescription"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </b-modal>
        <!-- Referans Güncelle  MODAL -->
        <b-modal
          id="RefUpd-modal"
          cancel-variant="outline-secondary"
          ok-title="Güncelle"
          cancel-title="İptal"
          size="lg"
          centered
          no-close-on-backdrop
          style="height:700px;"
          title="Referans Alanı Güncelle"
          @ok="onModalUpdateRef"
          @cancel="onModalCancelled"
        >
          <b-form>
            <b-row>
              <b-col>
                <b-form-group>
                  <label
                    for="applicantREFId"
                    hidden
                  >
                    Sıra No:</label>
                  <b-form-input
                    id="applicantREFId"
                    v-model="applicantREFId"
                    type="text"
                    placeholder="Sıra No"
                    hidden
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
              >
                <b-form-group>
                  <label for="referenceName">Adı Soyadı:</label>
                  <b-form-input
                    id="referenceName"
                    v-model="referenceName"
                    class="small"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
              >
                <b-form-group>
                  <label for="referenceEmail">Email:</label>
                  <b-form-input
                    id="referenceEmail"
                    v-model="referenceEmail"
                    class="small"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
              >
                <b-form-group>
                  <label for="referenceTelephone">Telefon:</label>
                  <b-form-input
                    id="referenceTelephone"
                    v-model="referenceTelephone"
                    class="small"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
              >
                <b-form-group>
                  <label for="referenceCompany">Kuruluş Adı:</label>
                  <b-form-input
                    id="referenceCompany"
                    v-model="referenceCompany"
                    class="small"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="6">
                <b-form-group>
                  <label for="referencePosition">Pozisyon:</label>
                  <v-select
                    id="referencePosition"
                    v-model="referencePosition"
                    label="staffPositionName"
                    :options="optionPoz"
                    :reduce="item => item.staffPositionName"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group>
                  <label for="referenceType">Referans Tipi: </label>
                  <v-select
                    id="referenceType"
                    v-model="referenceType"
                    label="referenceTypeDescription"
                    :options="optionRefType"
                    :reduce="item => item.referenceTypeDescription"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group>
                  <label for="hasInfo">Referansın bilgisi var mı?</label>
                  <div class="demo-inline-spacing">
                    <b-form-radio
                      v-model="hasInfo"
                      plain
                      :value="true"
                    >
                      Evet
                    </b-form-radio>
                    <b-form-radio
                      v-model="hasInfo"
                      plain
                      :value="false"
                    >
                      Hayır
                    </b-form-radio>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </b-modal>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BRow, BCol, BFormGroup, BButton, BFormInput, BModal, BForm, BButtonGroup, VBModal, BFormRadio,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { VueGoodTable } from 'vue-good-table'
import * as apiPozition from '@/api/tanimlar/personelpozisyon'
import * as apiApRef from '@/api/islemler/applicant/applicantReferences'
import * as apiRefType from '@/api/tanimlar/VKT/referenceType'
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  components: {
    VueGoodTable, BRow, BCol, BFormGroup, BButton, BFormInput, BModal, BForm, vSelect, BButtonGroup, BFormRadio,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  props: {
    applicantId: {
      type: Number,
      required: false,
      default: -1,
    },
  },
  data() {
    return {
      optionPoz: [],
      optionRefType: [],
      refCols: [
        {
          label: 'Sıra No',
          field: 'applicantREFId',
          hidden: true,
        },
        {
          label: 'Adı Soyadı',
          field: 'referenceName',
        },
        {
          label: 'Email',
          field: 'referenceEmail',
        },
        {
          label: 'Telefon',
          field: 'referenceTelephone',
        },
        {
          label: 'Kuruluş Adı',
          field: 'referenceCompany',
        },
        {
          label: 'Pozisyon',
          field: 'referencePosition',
        },
        {
          label: 'Referans Tipi',
          field: 'referenceType',
        },
        {
          label: 'Bilgisi var mı?',
          field: this.LabelInfo,
        },
        {
          label: 'İşlem',
          field: 'action',
          sortable: false,
          width: '15%',
        },
      ],
      refRows: [],
      applicantREFId: 0,
      referenceName: '',
      referenceEmail: '',
      referenceTelephone: '',
      referenceCompany: '',
      referencePosition: { staffPositionId: 0, staffPositionName: '' },
      referenceType: { referenceTypeId: 0, referenceTypeDescription: '' },
      hasInfo: true,
      enable: false,
      appId: this.applicantId,
    }
  },
  computed: {
    ...mapGetters({
      applicant: 'applicantInfo/GET_APPLICANT',
      getAdayId: 'applicantInfo/GET_ADAYID',
    }),
  },
  watch: {
    applicantId() {
      this.getApplicantData()
      if (this.getAdayId > 0) this.enable = true
    },
    // getAdayId() {
    //   this.getApplicantData()
    //   if (this.getAdayId > 0) this.enable = true
    // },
  },
  created() {
    this.getPositions()
    this.getAppReferences()
    this.getRefTypes()
  },
  methods: {
    ...mapMutations({
      setApplicant: 'applicantInfo/SET_APPLICANT',
    }),
    ...mapActions({
      fetchApplicant: 'applicantInfo/action_get_applicant',
    }),
    LabelInfo(rowObj) {
      let retval = ''
      if (rowObj.hasInfo === true) {
        retval = 'Evet'
      } else {
        retval = 'Hayır'
      }
      return retval
    },
    getApplicantData() {
      if (this.getAdayId > 0) {
        this.fetchApplicant(this.getAdayId)
        this.appId = this.getAdayId
        this.applicantId = this.getAdayId
        this.getAppReferences()
      }
    },
    getPositions() {
      apiPozition.GetPersonelPozisyonList().then(res => {
        this.optionPoz = res.data.list
      })
    },
    getAppReferences() {
      apiApRef.GetApplicantReferencesByApplicantId(this.appId).then(res => {
        this.refRows = res.data.list
      })
    },
    getRefTypes() {
      apiRefType.GetReferenceTypes().then(res => {
        this.optionRefType = res.data.list
      })
    },

    onDataGridItemClicked(params) {
      return params
    },

    onModalCancelled() {
      this.clearModal()
    },
    clearModal() {
      this.applicantREFId = 0
      this.referenceName = ''
      this.referenceEmail = ''
      this.referenceTelephone = ''
      this.referenceCompany = ''
      this.referenceType = ''
      this.referencePosition = ''
      this.hasInfo = true
    },
    onModalSaveRef() {
      const refModel = {
        applicantREFId: 0,
        applicantId: this.getAdayId,
        referenceName: this.referenceName,
        referenceEmail: this.referenceEmail,
        referenceTelephone: this.referenceTelephone,
        referenceCompany: this.referenceCompany,
        referencePosition: this.referencePosition,
        referenceType: this.referenceType,
        hasInfo: this.hasInfo,
      }
      apiApRef.AddApplicantReferences(refModel).then(() => {
        this.getAppReferences()
        this.getPositions()
        this.getRefTypes()
        this.clearModal()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Referans Ekle',
            text: 'Yeni kayıt eklendi',
            icon: 'PlusSquareIcon',
            variant: 'success',
          },
        })
        this.clearModal()
        this.appId = this.getAdayId
        this.getAppReferences()
      })
    },
    onModalUpdateRef() {
      const refModel = {
        applicantREFId: this.applicantREFId,
        applicantId: this.appId,
        referenceName: this.referenceName,
        referenceEmail: this.referenceEmail,
        referenceTelephone: this.referenceTelephone,
        referenceCompany: this.referenceCompany,
        referencePosition: this.referencePosition,
        referenceType: this.referenceType,
        hasInfo: this.hasInfo,
      }
      apiApRef.UpdateApplicantReferences(refModel).then(() => {
        this.getAppReferences()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Referans Güncelle',
            text: 'Kayıt Güncellendi',
            icon: 'PlusSquareIcon',
            variant: 'warning',
          },
        })
      })
      this.clearModal()
    },
    onUpdateRefBtnClicked(row) {
      this.applicantREFId = row.applicantREFId
      this.referenceName = row.referenceName
      this.referenceEmail = row.referenceEmail
      this.referenceTelephone = row.referenceTelephone
      this.referenceCompany = row.referenceCompany
      this.referencePosition = row.referencePosition
      this.referenceType = row.referenceType
      this.hasInfo = row.hasInfo
      this.$bvModal.show('RefUpd-modal')
    },
    onDeleteRefBtnClicked(row) {
      this.$bvModal.msgBoxConfirm(` '${row.referenceName}' adlı referans silinecek, onaylıyor musunuz?`, {
        title: 'Uyarı!',
        size: 'md',
        buttonSize: 'md',
        okVariant: 'danger',
        okTitle: 'Evet',
        cancelTitle: 'Hayır',
        footerClass: 'p-2',
        hideHeaderClose: true,
        noCloseOnBackdrop: true,
        centered: true,
      })
        .then(value => {
          if (value) {
            apiApRef.DeleteApplicantReferences(row.applicantREFId).then(() => {
              this.getAppReferences()
            })
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Kayıt Silindi',
                text: `${row.referenceName} adlı referans kaydı silindi.`,
                icon: 'DeleteIcon',
                variant: 'danger',
              },
            })
          }
        })
      this.clearModal()
    },
    onLoadSelects() {
      this.getRefTypes()
      this.getPositions()
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
